<template>
  <div class="custom-select">
    <div class="custom-select__title custom-select__title--main">Доход</div>
    <v-select
      :disabled="!isEdit && !isNew"
      :value="income"
      @input="$emit('update:income', $event)"
      :items="incomes"
      menu-props="auto"
      item-text="title"
      item-value="value"
      hide-details
      class="custom-select__field pa-0 ma-0"
      multiple
    ></v-select>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import axios from "axios";
import dbg from "@/plugins/dbg";

export default {
  name: "Income",
  props: {
    isNew: {
      type: Boolean,
      required: false
    },
    isEdit: {
      type: Boolean,
      required: false
    },
    income: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    incomes: []
  }),
  computed: {
    ...mapGetters("Orders", ["orderWasDeleted"]),
  },
  methods: {
    async getIncomes() {
      try {
        const response = await axios.get(`${this.$baseURL}rich`, {
          headers: {
            'Authorization': `Bearer ${window.localStorage.getItem('userToken')}`
          }
        });
        const _tempIncomes = Object.values(response.data).map(el => ({ value: el.id, title: `${el.name} тыс.руб` }));
        this.incomes = _tempIncomes;
      } catch (e) {
        dbg('error',e)
      }
    }
  },
  created() {
    this.getIncomes();
  }
};
</script>
