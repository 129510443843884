<template>
  <v-tooltip top left>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <v-checkbox
          class="rounded-checkbox"
          rounded
          color="accent"
          :disabled="orderWasDeleted"
          :input-value="
            form['approved_' + entity] && form['approved_' + entity].id
              ? true
              : false
          "
          @change="editStatusApprove($event, entity)"
        ></v-checkbox>
      </span>
    </template>
    <span>
      Изменить статус
    </span>
  </v-tooltip>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import dbg from "@/plugins/dbg";
import requestControl from "@/mixins/requestControl"; //checkRequestOnError//loading
import {
  APPROVE_ORDER,
  UNAPPROVE_ORDER,
  GET_ORDER
} from "../../../../../store/const/orders";
import { GET_USERS } from "../../../../../store/const/users";

export default {
  mixins: [requestControl],
  props: {
    entity: String,
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      outlined: false
    };
  },
  computed: {
    ...mapState("Orders", {
      order: "currentOrder"
    }),
    ...mapGetters("Auth", {
      selfId: "getUserId"
    }),
    ...mapGetters("Orders", ["orderWasDeleted"]),
    isLoading() {
      return (
        this.loading(GET_ORDER) === "loading" &&
        this.loading(GET_USERS) === "loading"
      );
    }
  },
  methods: {
    ...mapActions("Orders", {
      approveOrder: APPROVE_ORDER,
      unapproveOrder: UNAPPROVE_ORDER
    }),
    async editStatusApprove(bool, type) {
      dbg("Status: ", bool, "; Type:", type);
      const changeStatusFunc = bool ? this.approveOrder : this.unapproveOrder;
      try {
        await changeStatusFunc({ id: this.$route.params.id, type });
      } catch (err) {
        dbg(err);
      }
      if (this.checkRequestOnError(bool ? APPROVE_ORDER : UNAPPROVE_ORDER)) {
        this.$notify({
          type: "succ",
          text: bool ? "Бронь подтверждена" : "Подтвержение снято"
        });
        this.$emit("updateOrder", bool);
      }
    }
  }
};
</script>

<style></style>
