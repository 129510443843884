<template>
  <div class="custom-select">
    <div class="custom-select__title custom-select__title--age">
      Возраст
    </div>
    <div class='d-flex'>
        <v-select
            :disabled="!isEdit && !isNew"
            :value="min"
            @input="inputMinHandler"
            :items="allAges.mins"
            menu-props="auto"
            persistent-hint
            single-line="single-line"
            hide-details
            class="custom-select__field pa-0 ma-0"
            placeholder="от 17"
        >
            <template v-slot:selection="{ item }">
                <span class="custom-select__field-title">{{ item.title }}</span>
            </template>
            <template v-slot:item="{ item }">
        <span
            v-if="
            typeof max === 'string' ||
              item.value < max ||
              typeof item.value === 'string'
          "
        >
          {{ item.title }}
        </span>
                <span v-else disabled>{{ item.title }}</span>
            </template>
        </v-select>
        <span style="margin: 0 1em;">-</span>
        <v-select
            :disabled="!isEdit && !isNew"
            :value="max"
            @input="$emit('update:max', $event)"
            :items="allAges.maxes"
            menu-props="auto"
            persistent-hint
            single-line="single-line"
            hide-details
            class="custom-select__field pa-0 ma-0"
            placeholder="до 59"
        >
            <template v-slot:selection="{ item }">
                <span class="custom-select__field-title">{{ item.title }}</span>
            </template>
            <template v-slot:item="{ item }">
        <span v-if="min == '' || (item.value &gt; min || item.value == '')">
          {{ item.title }}</span
        >
                <span v-else disabled="disabled">{{ item.title }}</span></template
            >
        </v-select>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";

export default {
  name: "Age",
  props: {
    isNew: {
      type: Boolean,
      required: false
    },
    isEdit: {
      type: Boolean,
      required: false
    },
    min: {
      type: [Number, String],
      required: true
    },
    max: {
      type: [Number, String],
      required: false
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters("Orders", {
      orderWasDeleted: 'orderWasDeleted',
      allDemography: 'getAllDemography'
    }),
    allAges() {
      let demoMin = [];
      let demoMax = [];
      if (this.allDemography.length) {
        for (const index in this.allDemography) {
          if (this.allDemography[index]['gender'] === 'Not Defined') {
            demoMin.push({
              value: this.allDemography[index]['minAge'],
              title: `${this.allDemography[index]['minAge']} лет`
            });
            demoMax.push({
              value: this.allDemography[index]['maxAge'],
              title: `${this.allDemography[index]['maxAge']} лет`
            })
          }
        }
      }
      // ages min disables
      if (typeof this.max !== 'string') {
        demoMin = demoMin.map(age => {
          if (age.value >= this.max) {
            return {
              ...age,
              disabled: true
            }
          }
          return age;
        })
      }
      // ages max disables
      if (typeof this.min !== 'string') {
        demoMax = demoMax.map(age => {
          if (age.value <= this.min) {
            return {
              ...age,
              disabled: true
            }
          }
          return age;
        })
      }
      return {
        mins: demoMin,
        maxes: demoMax
      }
    },
  },
  methods: {
    inputMinHandler(val) {
      this.$emit('update:min', val);
    }
  }
};
</script>

<style scoped>
.custom-select__title {
  font-size: 14px;
}

.custom-select__title--age {
  margin-right: 0;
}

.custom-select__field-title {
  white-space: nowrap;
}
</style>
