var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-select"},[_c('div',{staticClass:"custom-select__title custom-select__title--age"},[_vm._v(" Возраст ")]),_c('div',{staticClass:"d-flex"},[_c('v-select',{staticClass:"custom-select__field pa-0 ma-0",attrs:{"disabled":!_vm.isEdit && !_vm.isNew,"value":_vm.min,"items":_vm.allAges.mins,"menu-props":"auto","persistent-hint":"","single-line":"single-line","hide-details":"","placeholder":"от 17"},on:{"input":_vm.inputMinHandler},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"custom-select__field-title"},[_vm._v(_vm._s(item.title))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [(
          typeof _vm.max === 'string' ||
            item.value < _vm.max ||
            typeof item.value === 'string'
        )?_c('span',[_vm._v(" "+_vm._s(item.title)+" ")]):_c('span',{attrs:{"disabled":""}},[_vm._v(_vm._s(item.title))])]}}])}),_c('span',{staticStyle:{"margin":"0 1em"}},[_vm._v("-")]),_c('v-select',{staticClass:"custom-select__field pa-0 ma-0",attrs:{"disabled":!_vm.isEdit && !_vm.isNew,"value":_vm.max,"items":_vm.allAges.maxes,"menu-props":"auto","persistent-hint":"","single-line":"single-line","hide-details":"","placeholder":"до 59"},on:{"input":function($event){return _vm.$emit('update:max', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"custom-select__field-title"},[_vm._v(_vm._s(item.title))])]}},{key:"item",fn:function(ref){
        var item = ref.item;
return [(_vm.min == '' || (item.value > _vm.min || item.value == ''))?_c('span',[_vm._v(" "+_vm._s(item.title))]):_c('span',{attrs:{"disabled":"disabled"}},[_vm._v(_vm._s(item.title))])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }