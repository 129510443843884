<template>
  <div
    style="height: 100%; display: flex; flex-direction: column; align-items: start;"
  >
    <div class="custom-select__wrapper">
      <div class="custom-select">
        <v-skeleton-loader
          :loading="syncLoading"
          type="chip"
          class="custom-select__title"
        >
          Статус
        </v-skeleton-loader>
        <v-skeleton-loader :loading="syncLoading" type="table-cell">
          <status-chip
            v-if="isNew"
            :loading="isLoading"
            dense
            status="New"
            small
            description="Новая"
          />
          <v-select
            v-else
            placeholder="Не выбран"
            hide-details
            class="pa-0 ma-0"
            :value.sync="status"
            :items="statusArray"
            item-text="description"
            item-value="id"
            @input="$emit('update:status', $event)"
          >
            <template v-slot:selection="{ item }">
              <status-chip
                :loading="isLoading"
                dense
                :status="item.id"
                small
                :description="item.description"
              />
            </template>
            <template v-slot:item="{ item }">
              <status-chip
                :loading="isLoading"
                dense
                :status="item.id"
                small
                :description="item.description"
              />
            </template>
          </v-select>
        </v-skeleton-loader>

        <!--

        -->
        <!--        <div class="custom-select__status">На согласовании</div>-->
      </div>
    </div>
    <div class="custom-select__wrapper">
      <div class="custom-select">
        <v-skeleton-loader
          class="custom-select__title"
          :loading="syncLoading"
          type="chip"
          >Приоритет</v-skeleton-loader
        >
        <v-skeleton-loader :loading="syncLoading" type="table-cell">
          <v-select
            placeholder="Не выбран"
            :disabled="!isEdit && !isNew"
            hide-details
            class="pa-0 ma-0"
            :value="priority"
            :items="priorities"
            item-text="description"
            item-value="id"
            @input="$emit('update:priority', $event)"
          ></v-select>
        </v-skeleton-loader>
        <slot></slot>
      </div>
    </div>
    <div class="custom-select__wrapper">
      <div class="custom-select">
        <v-skeleton-loader
          class="custom-select__title"
          :loading="syncLoading"
          type="chip"
          >Дата</v-skeleton-loader
        >
        <v-skeleton-loader :loading="syncLoading" type="table-cell">
          <v-datetime-picker
            :disabled="!isEdit && !isNew"
            :textFieldProps="{
              readOnly: !isEdit && !isNew,
              disabled: !isEdit && !isNew,
              outlined: true,
              placeholder: 'Дата'
            }"
            :value="calc_deadline"
            @input="updateCalcDeadLine"
          ></v-datetime-picker>
        </v-skeleton-loader>
        <slot></slot>
      </div>
    </div>

    <settings-panel-item
      :isEdit="isEdit"
      :isNew="isNew"
      :value.sync="keepers.account"
      @input="$emit('update:keepers.account', $event)"
      :items="keepersList.account"
      title="Аккаунт"
    >
    </settings-panel-item>
    <settings-panel-item
      v-if="currentUserRole != 'trader'"
      :isEdit="isEdit"
      :isNew="isNew"
      :value.sync="keepers.manager"
      @input="$emit('update:keepers.manager', $event)"
      :items="keepersList.manager"
      title="Менеджер"
    >
      <template #prepend="">
        <v-skeleton-loader :loading="syncLoading">
          <toggle-approve-order
            entity="manager"
            v-if="!isNew"
            :form="form"
            @updateOrder="
              $emit(
                'update:form',
                $event
                  ? (form.approved_manager = true)
                  : (form.approved_manager = false)
              )
            "
          ></toggle-approve-order>
        </v-skeleton-loader>
      </template>
    </settings-panel-item>
    <settings-panel-item
      v-if="currentUserRole != 'manager'"
      :isEdit="isEdit"
      :isNew="isNew"
      :value.sync="keepers.trader"
      @input="$emit('update:keepers.trader', $event)"
      :items="keepersList.trader"
      title="Трейдер"
    >
      <template #prepend="">
        <v-skeleton-loader :loading="syncLoading">
          <toggle-approve-order
            entity="trader"
            v-if="!isNew"
            :form="form"
            @updateOrder="
              $emit(
                'update:form',
                $event
                  ? { ...form, approved_trader: true }
                  : { ...form, approved_trader: false }
              )
            "
          ></toggle-approve-order>
        </v-skeleton-loader>
      </template>
      <!--      <v-radio color="#3751FF" value="trader"></v-radio>-->
    </settings-panel-item>
    <div
      v-if="['root'].includes(currentUserRole)"
      class="custom-select__wrapper"
    >
      <div class="custom-select">
        <div class="custom-select__title" style="width: auto;">
          Клиентская цена
        </div>
        <div class="custom-select__value">0р</div>
      </div>
    </div>

    <v-skeleton-loader
      :loading="syncLoading"
      type="chip"
      style="display: flex; justify-content: space-between;align-items: center; padding: 0 70px 25px; margin: auto 0 0;"
    >
      <div style="display: flex;align-items: center;">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="square-icon"
              v-if="!form.deleted_at"
              @click="deleteOrder"
              rounded
              icon
              v-bind="attrs"
              v-on="on"
            >
              <svg class="square-icon">
                <use xlink:href="@/assets/spriteReservation.svg#archive"></use>
              </svg>
            </v-btn>
            <v-btn
              v-else-if="currentUserRole === 'root'"
              color="primary"
              rounded
              @click="repairOrder({ id: $route.params.id })"
              v-bind="attrs"
              v-on="on"
            >
              Восстановить Бронь
            </v-btn>
          </template>
          <span>{{
            form.deleted_at
              ? new Date(form.deleted_at).toLocaleDateString()
              : "Архивировать"
          }}</span>
        </v-tooltip>
        <v-tooltip top v-if="!form.deleted_at">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="square-icon"
              rounded
              icon
              @click="removeMyself"
              v-bind="attrs"
              v-on="on"
              style="margin-left: 34px;"
            >
              <svg class="square-icon">
                <use
                  xlink:href="@/assets/spriteReservation.svg#responsibility"
                ></use>
              </svg>
            </v-btn>
          </template>
          <span>Снять ответственность</span>
        </v-tooltip>
      </div>
      <v-tooltip bottom v-if="!form.deleted_at">
        <template v-slot:activator="{ on, attrs }">
          <button
            v-bind="attrs"
            v-on="on"
            class="ml-6 custom-icon-container"
            :class="{ active: form.hidden_from_manager }"
            v-if="['root', 'trader', 'account'].includes(currentUserRole)"
            @click="changeHiddenFromManagerState"
            :disabled="!isEdit && !isNew"
          >
            <svg class="custom-icon">
              <use xlink:href="@/assets/spriteReservation.svg#show"></use>
            </svg>
          </button>
        </template>
        <span>Скрыть от менеджера</span>
      </v-tooltip>
      <v-tooltip v-if="!isNew && order.id && order.brand" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            v-if="['root', 'trader', 'account'].includes(currentUserRole)"
            class="ml-6 custom-icon-container"
            :class="{ active: form.hidden_from_manager }"
            :to="{
              name: 'newReservation',
              params: { id: order.brand.id },
              query: { copyOrder: order.id }
            }"
            large
            icon
          >
            <v-icon color="grey">mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>Скопировать настройки</span>
      </v-tooltip>
    </v-skeleton-loader>
  </div>
</template>

<script>
import { AUTH_ABOUT_ME } from "@/store/const/auth";
import requestControl from "@/mixins/requestControl";
import { mapActions, mapGetters, mapState } from "vuex";
import statusChip from "@/components/common/statusChip";
import SettingsPanelItem from "@/components/views/Reservation/components/Calculator/Blocks/General/SettingsPanelItem.vue";
import {
  EDIT_KEEPER_STATUS_FOR_ORDER,
  GET_ORDER,
  GET_ORDER_PRIORITIES,
  GET_PRICE
} from "@/store/const/orders";
import ToggleApproveOrder from "../../../ReservationSettingComponents/ToggleApproveOrder";
import { DELETE_ORDER, REPAIR_ORDER, UPDATE_ORDER } from "@/store/const/orders";
import CustomDatePicker from "@/components/common/CustomDatePicker";
import VDatetimePicker from "@/components/common/DateTimePicker";
import { formatDate } from "@/plugins/helpers";
import { GET_ORDER_STATUSES } from "../../../../../../../store/const/orders";
export default {
  name: "SettingsPanel",
  components: {
    VDatetimePicker,
    CustomDatePicker,
    ToggleApproveOrder,
    statusChip,

    SettingsPanelItem
  },
  mixins: [requestControl],
  props: {
    calc_deadline: {
      type: String,
      required: false
    },
    isNew: {
      type: Boolean,
      required: false
    },
    isEdit: {
      type: Boolean,
      required: false
    },
    status: {
      type: String,
      required: false,
      default: "new"
    },
    form: {
      type: Object,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },

    priority: {
      type: String,
      required: false,
      default: "regular"
    },
    keepers: {
      type: [Object, Array],
      required: false
    },
    keepersList: {
      type: Object,
      required: false
    },
    hidden_from_manager: {
      type: [Boolean, null],
      required: true
    }
  },
  data() {
    return {
      flights: [],
      items: ["test"],
      type: ""
    };
  },
  async created() {
    await this.getPriorities();
    if (this.statusArray.length === 0) {
      await this.getStatuses();
    }
  },
  computed: {
    ...mapGetters("Auth", {
      currentUserRole: "myRole",
      userId: "getUserId"
    }),
    ...mapGetters("Orders", {
      order: "getOrder",
      orderWasDeleted: "orderWasDeleted",
      syncLoading: "getOrderSyncLoading",
      statusArray: "getStatuses"
    }),
    ...mapState("Orders", ["priorities"]),
    isLoading() {
      return (
        this.loading(GET_ORDER) === "loading" ||
        this.loading(AUTH_ABOUT_ME) === "loading" ||
        this.syncLoading
      );
    },
    retailPrice: {
      get() {
        return this.$store.state.Orders.prices.retail;
      }
    }
  },
  methods: {
    ...mapActions("Orders", {
      getPriorities: GET_ORDER_PRIORITIES,
      getPrice: GET_PRICE,
      updateOrder: UPDATE_ORDER,
      archiveOrder: DELETE_ORDER,
      repairOrder: REPAIR_ORDER,
      changeStatusForOrder: EDIT_KEEPER_STATUS_FOR_ORDER,
      getStatuses: GET_ORDER_STATUSES
    }),
    updateCalcDeadLine(newValue) {
      this.$emit(
        "update:calc_deadline",
        formatDate(newValue, {
          withTime: true,
          separator: "-"
        })
      );
    },
    async deleteOrder() {
      const res = await this.$confirm("Подтвердите удаление", {
        title: "Удаленную бронь может восстановить только администратор"
      });
      if (res) {
        this.archiveOrder({ id: this.$route.params.id });
        if (this.checkRequestOnError(DELETE_ORDER)) {
          this.$notify({
            type: "succ",
            text: "Бронь успешно удалена"
          });
          this.$router.push({ name: "reservation" });
        }
      }
    },
    async changeHiddenFromManagerState() {
      let data = {
        data: {
          hidden_from_manager: !this.hidden_from_manager
        },
        id: this.$route.params.id
      };
      await this.updateOrder(data);
      if (this.checkRequestOnError(UPDATE_ORDER)) {
        this.$notify({
          type: "succ",
          text: `Бронь успешно ${
            this.hidden_from_manager ? "открыта" : "скрыта"
          }`
        });
        this.$router.push({ name: "reservation" });
      }
    },
    async removeMyself() {
      const res = await this.$confirm("Подтвердите действие", {
        title: "Снять с себя ответвенность"
      });
      if (res) {
        this.changeStatusForOrder({
          id: this.$route.params.id,
          verb: "remove",
          user: this.userId
        });
        if (this.checkRequestOnError(EDIT_KEEPER_STATUS_FOR_ORDER)) {
          this.$notify({
            type: "succ",
            text: "Вы больше не ответвенный за эту бронь"
          });
          this.$router.push({ name: "reservation" });
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.custom-icon {
  width: 34px;
  height: 34px;
  color: #9fa2b4;
  cursor: pointer;
  transition: 0.2s ease-out;
}
.square-icon {
  width: 34px;
  height: 34px;
  color: #9fa2b4;
}

.custom-select__title {
  white-space: nowrap;
}

.custom-icon-container {
  display: flex;
}

.custom-icon-container.active .custom-icon {
  color: var(--v-accent-base);
}
.setting-panel-footer {
  flex-direction: column;
  align-items: start;
  border-top: thin solid rgba(0, 0, 0, 0.12);
  display: flex;
}
</style>
