var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%","display":"flex","flex-direction":"column","align-items":"start"}},[_c('div',{staticClass:"custom-select__wrapper"},[_c('div',{staticClass:"custom-select"},[_c('v-skeleton-loader',{staticClass:"custom-select__title",attrs:{"loading":_vm.syncLoading,"type":"chip"}},[_vm._v(" Статус ")]),_c('v-skeleton-loader',{attrs:{"loading":_vm.syncLoading,"type":"table-cell"}},[(_vm.isNew)?_c('status-chip',{attrs:{"loading":_vm.isLoading,"dense":"","status":"New","small":"","description":"Новая"}}):_c('v-select',{staticClass:"pa-0 ma-0",attrs:{"placeholder":"Не выбран","hide-details":"","value":_vm.status,"items":_vm.statusArray,"item-text":"description","item-value":"id"},on:{"update:value":function($event){_vm.status=$event},"input":function($event){return _vm.$emit('update:status', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('status-chip',{attrs:{"loading":_vm.isLoading,"dense":"","status":item.id,"small":"","description":item.description}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('status-chip',{attrs:{"loading":_vm.isLoading,"dense":"","status":item.id,"small":"","description":item.description}})]}}])})],1)],1)]),_c('div',{staticClass:"custom-select__wrapper"},[_c('div',{staticClass:"custom-select"},[_c('v-skeleton-loader',{staticClass:"custom-select__title",attrs:{"loading":_vm.syncLoading,"type":"chip"}},[_vm._v("Приоритет")]),_c('v-skeleton-loader',{attrs:{"loading":_vm.syncLoading,"type":"table-cell"}},[_c('v-select',{staticClass:"pa-0 ma-0",attrs:{"placeholder":"Не выбран","disabled":!_vm.isEdit && !_vm.isNew,"hide-details":"","value":_vm.priority,"items":_vm.priorities,"item-text":"description","item-value":"id"},on:{"input":function($event){return _vm.$emit('update:priority', $event)}}})],1),_vm._t("default")],2)]),_c('div',{staticClass:"custom-select__wrapper"},[_c('div',{staticClass:"custom-select"},[_c('v-skeleton-loader',{staticClass:"custom-select__title",attrs:{"loading":_vm.syncLoading,"type":"chip"}},[_vm._v("Дата")]),_c('v-skeleton-loader',{attrs:{"loading":_vm.syncLoading,"type":"table-cell"}},[_c('v-datetime-picker',{attrs:{"disabled":!_vm.isEdit && !_vm.isNew,"textFieldProps":{
            readOnly: !_vm.isEdit && !_vm.isNew,
            disabled: !_vm.isEdit && !_vm.isNew,
            outlined: true,
            placeholder: 'Дата'
          },"value":_vm.calc_deadline},on:{"input":_vm.updateCalcDeadLine}})],1),_vm._t("default")],2)]),_c('settings-panel-item',{attrs:{"isEdit":_vm.isEdit,"isNew":_vm.isNew,"value":_vm.keepers.account,"items":_vm.keepersList.account,"title":"Аккаунт"},on:{"update:value":function($event){return _vm.$set(_vm.keepers, "account", $event)},"input":function($event){return _vm.$emit('update:keepers.account', $event)}}}),(_vm.currentUserRole != 'trader')?_c('settings-panel-item',{attrs:{"isEdit":_vm.isEdit,"isNew":_vm.isNew,"value":_vm.keepers.manager,"items":_vm.keepersList.manager,"title":"Менеджер"},on:{"update:value":function($event){return _vm.$set(_vm.keepers, "manager", $event)},"input":function($event){return _vm.$emit('update:keepers.manager', $event)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-skeleton-loader',{attrs:{"loading":_vm.syncLoading}},[(!_vm.isNew)?_c('toggle-approve-order',{attrs:{"entity":"manager","form":_vm.form},on:{"updateOrder":function($event){_vm.$emit(
              'update:form',
              $event
                ? (_vm.form.approved_manager = true)
                : (_vm.form.approved_manager = false)
            )}}}):_vm._e()],1)]},proxy:true}],null,false,1537967610)}):_vm._e(),(_vm.currentUserRole != 'manager')?_c('settings-panel-item',{attrs:{"isEdit":_vm.isEdit,"isNew":_vm.isNew,"value":_vm.keepers.trader,"items":_vm.keepersList.trader,"title":"Трейдер"},on:{"update:value":function($event){return _vm.$set(_vm.keepers, "trader", $event)},"input":function($event){return _vm.$emit('update:keepers.trader', $event)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-skeleton-loader',{attrs:{"loading":_vm.syncLoading}},[(!_vm.isNew)?_c('toggle-approve-order',{attrs:{"entity":"trader","form":_vm.form},on:{"updateOrder":function($event){return _vm.$emit(
              'update:form',
              $event
                ? Object.assign({}, _vm.form, {approved_trader: true})
                : Object.assign({}, _vm.form, {approved_trader: false})
            )}}}):_vm._e()],1)]},proxy:true}],null,false,54478167)}):_vm._e(),(['root'].includes(_vm.currentUserRole))?_c('div',{staticClass:"custom-select__wrapper"},[_vm._m(0)]):_vm._e(),_c('v-skeleton-loader',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center","padding":"0 70px 25px","margin":"auto 0 0"},attrs:{"loading":_vm.syncLoading,"type":"chip"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(!_vm.form.deleted_at)?_c('v-btn',_vm._g(_vm._b({staticClass:"square-icon",attrs:{"rounded":"","icon":""},on:{"click":_vm.deleteOrder}},'v-btn',attrs,false),on),[_c('svg',{staticClass:"square-icon"},[_c('use',{attrs:{"xlink:href":require("@/assets/spriteReservation.svg") + "#archive"}})])]):(_vm.currentUserRole === 'root')?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","rounded":""},on:{"click":function($event){return _vm.repairOrder({ id: _vm.$route.params.id })}}},'v-btn',attrs,false),on),[_vm._v(" Восстановить Бронь ")]):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.form.deleted_at ? new Date(_vm.form.deleted_at).toLocaleDateString() : "Архивировать"))])]),(!_vm.form.deleted_at)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"square-icon",staticStyle:{"margin-left":"34px"},attrs:{"rounded":"","icon":""},on:{"click":_vm.removeMyself}},'v-btn',attrs,false),on),[_c('svg',{staticClass:"square-icon"},[_c('use',{attrs:{"xlink:href":require("@/assets/spriteReservation.svg") + "#responsibility"}})])])]}}],null,false,1887642122)},[_c('span',[_vm._v("Снять ответственность")])]):_vm._e()],1),(!_vm.form.deleted_at)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(['root', 'trader', 'account'].includes(_vm.currentUserRole))?_c('button',_vm._g(_vm._b({staticClass:"ml-6 custom-icon-container",class:{ active: _vm.form.hidden_from_manager },attrs:{"disabled":!_vm.isEdit && !_vm.isNew},on:{"click":_vm.changeHiddenFromManagerState}},'button',attrs,false),on),[_c('svg',{staticClass:"custom-icon"},[_c('use',{attrs:{"xlink:href":require("@/assets/spriteReservation.svg") + "#show"}})])]):_vm._e()]}}],null,false,788806448)},[_c('span',[_vm._v("Скрыть от менеджера")])]):_vm._e(),(!_vm.isNew && _vm.order.id && _vm.order.brand)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(['root', 'trader', 'account'].includes(_vm.currentUserRole))?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-6 custom-icon-container",class:{ active: _vm.form.hidden_from_manager },attrs:{"to":{
            name: 'newReservation',
            params: { id: _vm.order.brand.id },
            query: { copyOrder: _vm.order.id }
          },"large":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-content-copy")])],1):_vm._e()]}}],null,false,275736764)},[_c('span',[_vm._v("Скопировать настройки")])]):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-select"},[_c('div',{staticClass:"custom-select__title",staticStyle:{"width":"auto"}},[_vm._v(" Клиентская цена ")]),_c('div',{staticClass:"custom-select__value"},[_vm._v("0р")])])}]

export { render, staticRenderFns }