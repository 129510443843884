<template>
  <v-row>
    <v-col cols="6">
      <socdem-sex
        :isEdit="isEdit"
        :isNew="isNew"
        :value="sex"
        @update:value="$emit('update:sex', $event)"
      ></socdem-sex>
    </v-col>
    <v-col cols="6">
      <socdem-age
        :isEdit="isEdit"
        :isNew="isNew"
        :min="min"
        :max="max"
        @update:min="$emit('update:min', $event)"
        @update:max="$emit('update:max', $event)"
      ></socdem-age>
    </v-col>
    <v-col cols="6">
      <socdem-income
        :isEdit="isEdit"
        :isNew="isNew"
        :income="income"
        @update:income="$emit('update:income', $event)"
      ></socdem-income>
    </v-col>
  </v-row>
</template>

<script>
import SocdemAge from "@/components/views/Reservation/components/Calculator/Tabs/Socdem/Age";
import SocdemIncome from "@/components/views/Reservation/components/Calculator/Tabs/Socdem/Income";
import SocdemSex from "@/components/views/Reservation/components/Calculator/Tabs/Socdem/Sex";
export default {
  name: "Socdem",
  components: {
    SocdemAge,
    SocdemIncome,
    SocdemSex
  },
  props: {
    isNew: {
      type: Boolean,
      required: false
    },
    isEdit: {
      type: Boolean,
      required: false
    },
    sex: {
      type: [String, Array],
      required: true
    },
    income: {
      type: Array,
      required: true
    },
    min: {
      type: [Number, String],
      required: true
    },
    max: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      withAges: false
    };
  }
};
</script>

<style scoped></style>
