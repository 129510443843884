export const mainCountries = ["Россия"];

export const badRegions = [
  "",
  "Brestskaya Oblast’",
  "Gomel’",
  "Gomelskaya Oblast",
  "Gorod Sevastopol"
];

export const mainRegions = [
  "Москва",
  "МО",
  "Санкт-Петербург",
  "Ленинградская область",
  "Киев",
  "Киевская область",
  "Минск",
  "Минская Область"
];

export const badCities = [
  "Gorod Sevastopol",
  "Letichev",
  "Berëzovka",
  "Kadiyevka",
  "Kryzhopol’",
  "Тячeв",
  "Ol’gino",
  "Gomel’",
  "Berëza",
  "Korostyshev",
  "Primorsk",
  "Pot’ma",
  "Gomelskaya Oblast",
  "Dzerzhinsk",
  "Kopan’",
  "Brestskaya Oblast’",
  "Dimitrovo",
  "Fëdorovka",
  "Razdel’naya",
  "Tel’manovo",
  "Chistyakovo",
  ""
];

export const mainCities = [
  "Москва",
  "Санкт-Петербург",
  "Архангельск",
  "Астрахань",
  "Белгород",
  "Благовещенск",
  "Брянск",
  "Владимир",
  "Волгоград",
  "Вологда",
  "Воронеж",
  "Иваново",
  "Иркутск",
  "Калининград",
  "Калуга",
  "Кемерово",
  "Кемерово",
  "Кострома",
  "Курган",
  "Курск",
  "Липецк",
  "Магадан",
  "Москва",
  "Мурманск",
  "Нижний Новгород",
  "Новгород",
  "Новосибирск",
  "Омск",
  "Оренбург",
  "Орел",
  "Пенза",
  "Псков",
  "Ростов-на-Дону",
  "Рязань",
  "Самара",
  "Саратов",
  "Севастополь",
  "Южно-Сахалинск",
  "Екатеринбург",
  "Смоленск",
  "Тамбов",
  "Тверь",
  "Томск",
  "Тула",
  "Тюмень",
  "Ульяновск",
  "Челябинск",
  "Ярославль",
  "Майкоп",
  "Горно-Алтайск",
  "Уфа",
  "Улан-Удэ",
  "Махачкала",
  "Магас",
  "Нальчик",
  "Элиста",
  "Черкесск",
  "Петрозаводск",
  "Сыктывкар",
  "Йошкар-Ола",
  "Саранск",
  "Якутск",
  "Владикавказ",
  "Казань",
  "Кызыл",
  "Ижевск",
  "Абакан",
  "Грозный",
  "Чебоксары",
  "Симферополь",
  "Барнаул",
  "Чита",
  "Петропавловск-Камчатский",
  "Краснодар",
  "Красноярск",
  "Пермь",
  "Владивосток",
  "Ставрополь",
  "Хабаровск",
  "Нарьян-Мар",
  "Ханты-Мансийск",
  "Анадырь",
  "Салехард",
  "Биробиджан",
  "Киев",
  "Минск",
  "Брест",
  "Витебск",
  "Гомель",
  "Гродно",
  "Могилев",
  "Винница",
  "Луцк",
  "Днепр",
  "Донецк",
  "Житомир",
  "Ужгород",
  "Запорожье",
  "Ивано-Франковск",
  "Кропивницкий",
  "Луганск",
  "Львов",
  "Николаев",
  "Одесса",
  "Полтава",
  "Ровно",
  "Сумы",
  "Тернополь",
  "Харьков",
  "Херсон",
  "Хмельницкий",
  "Черкассы",
  "Чернигов",
  "Черновцы"
];
