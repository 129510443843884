<template>
  <v-skeleton-loader :loading="getOrderSyncLoading" type="card">
    <v-card outlined>
      <div class="pa-8 ">
        <div
          class="d-inline-block text-h5 font-weight-bold"
          style="color: #252733"
        >
          Креативы
        </div>
        <span class="ml-2" style="font-size: 10px" v-show="isNew"
          >Добавление креатива возможно только после создания брони</span
        >

        <creative-popup
          v-if="isEdit && !isNew"
          button-text="Добавить креатив"
          :is-new="true"
          v-model="dialogState"
        ></creative-popup>
      </div>
      <creative-table class="mb-3"></creative-table>
    </v-card>
  </v-skeleton-loader>
</template>

<script>
import creativePopup from "@/components/management/creatives/Popup";
import creativeTable from "@/components/management/creatives/Table";
import { AUTH_ABOUT_ME } from "@/store/const/auth";
import requestControl from "@/mixins/requestControl";
import { mapActions, mapGetters, mapState } from "vuex";
import { GET_ORDER, GET_ORDER_FILES } from "@/store/const/orders";
export default {
  name: "Creatives",
  components: {
    creativePopup,
    creativeTable
    // creativeImage,
  },
  mixins: [requestControl],
  props: {
    isNew: {
      type: Boolean,
      required: false
    },
    isEdit: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      dialogState: false,
      creatives: [],
      headers: [
        {
          text: "Название",
          value: "name",
          sortable: false,
          align: "left"
        },
        {
          text: "Описание",
          value: "name",
          sortable: false,
          align: "left"
        },
        {
          text: "Добавлено",
          value: "name",
          sortable: false,
          align: "left"
        },
        {
          text: "Действие",
          value: "actions",
          sortable: false
        }
      ]
    };
  },

  computed: {
    ...mapState("Orders", ["orderFiles"]),
    isLoading() {
      return (
        this.loading(AUTH_ABOUT_ME) === "loading" ||
        this.loading(GET_ORDER) === "loading" ||
        this.loading(GET_ORDER_FILES) === "loading"
      );
    },
    ...mapGetters("Orders", ["orderWasDeleted", "getOrderSyncLoading"])
  },
  methods: {
    ...mapActions("Orders", {
      getCurrentOrdersFiles: GET_ORDER_FILES
    })
  },
  async created() {
    await this.getCurrentOrdersFiles({ id: this.$route.params.id });
  }
};
</script>
