<template>
  <v-dialog
    overlay-color="#040b3e"
    overlay-opacity="0.26"
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-col cols="12">
        <v-skeleton-loader
          transition="scale-transition"
          height="94"
          type="date-picker"
        >
          <div style="display: flex">
            <segment-intersections
              style="width: 100%"
              @editActiveBlockIndex="activeBlockIndex = $event; value[activeBlockIndex].length === 0 ? dialog = true: void(0)"
              :active-block="activeBlockIndex"
              :value="value"
              :form="form"
              @input="$emit('input', $event)"
              :isNew="isNew"
              :isEdit="isEdit"
            ></segment-intersections>

            <div
              v-if="!(!isEdit && !isNew)"
              class="custom-button"
              v-bind="attrs"
              v-on="on"
            >
              <div class="custom-button__icon">
                <v-icon small color="#9FA2B4">mdi-plus</v-icon>
              </div>
            </div>
            <!-- :selected-taxonomies-by-block="selectedSegments" -->
          </div>
        </v-skeleton-loader></v-col
      >
    </template>

    <v-card :disabled="orderWasDeleted" outlined="outlined">
      <v-card-title><span>Интересы</span></v-card-title>
      <v-card-text class="">
        <div class="py-2">
          <v-text-field
            label="Фильтр сегментов"
            placeholder="Поиск..."
            v-model="DMPSearch"
          >
          </v-text-field>
          <v-treeview
            style="overflow: auto; max-height: 500px"
            dense
            shaped
            rounded
            selected-color="accent"
            v-model="value[activeBlockIndex]"
            selectable
            :items="DMPTaxonomy"
            :search="DMPSearch"
          >
          </v-treeview>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import buildTreeMixin from "@/mixins/buildTree";
import segmentIntersections from "@/components/views/Reservation/components/ReservationSettingComponents/Calc/Segments/SegmentIntersections";
import { mapGetters } from "vuex";
export default {
  name: "Segments",
  components: {
    segmentIntersections
  },
  mixins: [buildTreeMixin],
  props: {
    value: {
      required: true,
      type: Array
    },
    isNew: {
      type: Boolean,
      required: false
    },
    isEdit: {
      type: Boolean,
      required: false
    },
    form: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      DMPTaxonomy: [],
      DMPSearch: "",
      allParentNodes: true,
      activeBlockIndex: 0,
      dialog: false
    };
  },
  computed: {
    ...mapGetters("Orders", ["orderWasDeleted"])
  },
  async created() {
    const segments = [
      { name: "D класс, средние", id: "0:50000080", pid: "0:70000001" },
      { name: "J класс, внедорожники", id: "0:50000085", pid: "0:70000001" },
      { name: "M класс, минивэны", id: "0:50000084", pid: "0:70000001" },
      { name: "S класс, спорт", id: "0:50000083", pid: "0:70000001" },
      { name: "А класс, люкс", id: "0:50000082", pid: "0:70000001" },
      { name: "А класс, экономичные", id: "0:50000077", pid: "0:70000001" },
      { name: "Австралия", id: "0:40010298", pid: "0:30040002" },
      {
        name: "Авто энтузиасты/покупатели",
        id: "0:20010001",
        pid: "0:70000028"
      },
      { name: "Азия", id: "0:50000047", pid: "0:30040002" },
      { name: "Аренда недвижимости", id: "0:50000070", pid: "0:70000037" },
      { name: "Африка", id: "0:40010179", pid: "0:30040002" },
      { name: "Банки", id: "0:40010238", pid: "0:90103118" },
      { name: "Баскетбол", id: "0:90109035", pid: "0:20010027" },
      {
        name: "Беременность и дети до 4 лет",
        id: "0:40010101",
        pid: "0:20010018"
      },
      { name: "Беременные", id: "0:90114008", pid: "0:20010018" },
      { name: "Бизнес", id: "0:90104000", pid: "" },
      { name: "Бросают курить", id: "0:40010414", pid: "0:20010011" },
      { name: "Бытовая техника", id: "0:40010762", pid: "0:20010028" },
      { name: "В браке", id: "0:90114004", pid: "0:70000016" },
      { name: "В класс, городские", id: "0:50000078", pid: "0:70000001" },
      { name: "Владельцы машин", id: "0:40010144", pid: "0:70000028" },
      { name: "Водные виды спорта", id: "0:90109010", pid: "0:20010027" },
      { name: "Воспитание детей 1-3 лет", id: "0:40014990", pid: "0:20010018" },
      { name: "Высокая мода", id: "0:20010022", pid: "0:30030006" },
      { name: "Городская недвижимость", id: "0:40500040", pid: "0:70000021" },
      { name: "Грузовики", id: "0:50000086", pid: "0:70000001" },
      { name: "Дети 3-6 лет", id: "0:40014766", pid: "0:20010018" },
      { name: "Дети до 1 года", id: "0:40014626", pid: "0:20010018" },
      { name: "Детская одежда", id: "0:90108015", pid: "0:30030006" },
      { name: "Диеты и фитнесс", id: "0:20010005", pid: "0:20010027" },
      { name: "Дом и огород", id: "0:30020009", pid: "" },
      { name: "Дошкольники", id: "0:40010284", pid: "0:20010018" },
      { name: "Другие финансовые услуги", id: "0:90103145", pid: "0:30020007" },
      { name: "Е класс, бизнес", id: "0:50000081", pid: "0:70000001" },
      { name: "Еда и напитки", id: "0:20010033", pid: "" },
      { name: "Есть дети", id: "0:40010020", pid: "0:70000016" },
      { name: "Женские товары", id: "0:40010301", pid: "0:30030006" },
      { name: "Женщины", id: "0:40050910", pid: "0:40050908" },
      { name: "Загородная недвижимость", id: "0:50000064", pid: "0:70000021" },
      { name: "Здоровый образ жизни", id: "0:20010009", pid: "0:70000026" },
      { name: "Здоровье", id: "0:20010011", pid: "" },
      { name: "Зимние виды спорта", id: "0:40010401", pid: "0:20010027" },
      {
        name: "Знаменитости и шоу-бизнес",
        id: "0:40010234",
        pid: "0:20010006"
      },
      { name: "Игры", id: "0:20010002", pid: "0:20010006" },
      { name: "Инвесторские услуги", id: "0:40010066", pid: "0:90103118" },
      { name: "Интересуются здоровьем", id: "0:90102001", pid: "0:20010011" },
      {
        name: "Интересуются медицинскими услугами",
        id: "0:30020008",
        pid: "0:20010011"
      },
      {
        name: "Интересуются покупкой авто (класс)",
        id: "0:70000001",
        pid: "0:20010001"
      },
      { name: "Интернет покупатели", id: "0:20010024", pid: "0:20010031" },
      { name: "Искусство, Культура И Литература", id: "0:90116000", pid: "" },
      {
        name: "Коммерческая недвижимость",
        id: "0:40500037",
        pid: "0:70000021"
      },
      { name: "Коммерческие авто", id: "0:40014912", pid: "0:70000001" },
      { name: "Компьютеры", id: "0:40010256", pid: "0:20010028" },
      { name: "Косметология", id: "0:40015359", pid: "0:90108001" },
      { name: "Красота и уход", id: "0:90108001", pid: "" },
      { name: "Красота", id: "0:85000119", pid: "0:20010012" },
      { name: "Кредитование", id: "0:40010877", pid: "0:90103118" },
      { name: "Латинская Америка", id: "0:40010297", pid: "0:30040002" },
      { name: "Лекарства и препараты", id: "0:50000000", pid: "0:20010011" },
      { name: "Любители скидок", id: "0:20010016", pid: "0:20010031" },
      { name: "Любители техники и электроники", id: "0:20010028", pid: "" },
      { name: "Мало смотрят ТВ", id: "0:40010352", pid: "0:70000026" },
      { name: "Медицинские форумы", id: "0:40010629", pid: "0:20010011" },
      { name: "Меломаны онлайн", id: "0:20010017", pid: "0:20010006" },
      { name: "Мобильные устройства", id: "0:30030011", pid: "0:20010028" },
      { name: "Мода", id: "0:30030006", pid: "" },
      { name: "Мото", id: "0:50000142", pid: "0:70000028" },
      { name: "Мужские товары", id: "0:40010302", pid: "0:30030006" },
      {
        name: "Мужское хобби - техника и развлечения",
        id: "0:20010010",
        pid: "0:20010012"
      },
      { name: "Музыка", id: "0:30030013", pid: "0:20010012" },
      { name: "Наука", id: "0:90117000", pid: "" },
      { name: "Не в браке", id: "0:10060001", pid: "0:70000016" },
      { name: "Недавно переехали", id: "0:40010233", pid: "0:70000026" },
      { name: "Недвижимость заграницей", id: "0:50000068", pid: "0:70000021" },
      {
        name: "Недвижимость среднего класса",
        id: "0:50000075",
        pid: "0:70000025"
      },
      {
        name: "Недвижимость эконом-класса",
        id: "0:50000074",
        pid: "0:70000025"
      },
      { name: "Недвижимость", id: "0:20010038", pid: "" },
      { name: "Новобрачные", id: "0:90114005", pid: "0:70000016" },
      { name: "Новости", id: "0:20010036", pid: "" },
      { name: "Образ жизни", id: "0:70000026", pid: "" },
      { name: "Образование", id: "0:40010326", pid: "" },
      { name: "Обустройство дома", id: "0:20010013", pid: "0:30020009" },
      { name: "Парфюмерия", id: "0:40010306", pid: "0:90108001" },
      { name: "Питомцы", id: "0:30030016", pid: "0:20010012" },
      {
        name: "Планирование беременности",
        id: "0:90114007",
        pid: "0:20010018"
      },
      { name: "По типу недвижимости", id: "0:70000021", pid: "0:20010038" },
      { name: "По типу сервиса", id: "0:70000037", pid: "0:20010038" },
      { name: "По типу стоимости", id: "0:70000025", pid: "0:20010038" },
      { name: "Подарки и цветы", id: "0:90000005", pid: "0:20010006" },
      { name: "Подростки", id: "0:90114011", pid: "0:20010018" },
      { name: "Поиск работы", id: "0:20010014", pid: "0:70000036" },
      {
        name: "Покупатели продуктов онлайн",
        id: "0:30030008",
        pid: "0:20010033"
      },
      {
        name: "Покупающие товары заграницей",
        id: "0:90119004",
        pid: "0:20010031"
      },
      { name: "Покупки", id: "0:20010031", pid: "" },
      { name: "Премиум виды спорта", id: "0:40014933", pid: "0:20010027" },
      { name: "Продажа недвижимости", id: "0:50000071", pid: "0:70000037" },
      { name: "Продукты питания", id: "0:90111017", pid: "0:20010033" },
      { name: "Профессия и карьера", id: "0:70000036", pid: "" },
      { name: "Путешествия (сервисы)", id: "0:90106039", pid: "0:20010029" },
      { name: "Путешествия", id: "0:20010029", pid: "" },
      { name: "Развлечения", id: "0:20010006", pid: "" },
      { name: "Рестораны", id: "0:30020015", pid: "0:20010033" },
      { name: "Рецепты, кулинария", id: "0:20010021", pid: "0:20010033" },
      {
        name: "Родители выпускников и абитуриентов",
        id: "0:89000212",
        pid: "0:20010018"
      },
      { name: "Родители и дети", id: "0:20010018", pid: "0:90114000" },
      { name: "Россия", id: "0:40010123", pid: "0:30040002" },
      { name: "С класс, универсальные", id: "0:50000079", pid: "0:70000001" },
      { name: "Сад и огород", id: "0:40010107", pid: "0:30020009" },
      { name: "Северная Америка", id: "0:40010294", pid: "0:30040002" },
      { name: "Семейное положение", id: "0:70000016", pid: "0:90114000" },
      { name: "Семейный досуг с детьми", id: "0:40015744", pid: "0:20010018" },
      { name: "Семья и дети", id: "0:90114000", pid: "" },
      { name: "События и мероприятия", id: "0:20010007", pid: "0:20010006" },
      { name: "Спорт", id: "0:20010027", pid: "" },
      { name: "Спортивные товары", id: "0:30030017", pid: "0:20010027" },
      { name: "Среднее специальное", id: "0:10050002", pid: "0:40010326" },
      { name: "Страны", id: "0:30040002", pid: "0:20010029" },
      { name: "Страхование", id: "0:40535408", pid: "0:90103118" },
      { name: "Студенты и абитуриенты", id: "0:40535287", pid: "0:40535280" },
      { name: "ТВ и видео", id: "0:90113049", pid: "0:20010028" },
      { name: "Телевидение", id: "0:40010293", pid: "0:20010006" },
      { name: "Товары для детей", id: "0:20010019", pid: "0:20010018" },
      { name: "Транспорт", id: "0:70000028", pid: "" },
      { name: "Трудоустроенные", id: "0:90115001", pid: "0:70000036" },
      { name: "Фильмы и сериалы", id: "0:40010287", pid: "0:20010006" },
      {
        name: "Финансовые услуги в персональной сфере",
        id: "0:90103118",
        pid: "0:30020007"
      },
      { name: "Финансы и страхование", id: "0:30020007", pid: "" },
      { name: "Фото и видео товары", id: "0:40010758", pid: "0:20010028" },
      { name: "Фотографы", id: "0:40535362", pid: "0:20010012" },
      { name: "Футбол", id: "0:40010181", pid: "0:20010027" },
      { name: "Хобби своими руками", id: "0:90112013", pid: "0:20010012" },
      { name: "Хобби", id: "0:20010012", pid: "" },
      { name: "Элитная недвижимость", id: "0:50000076", pid: "0:70000025" },
      { name: "Юмор", id: "0:20010040", pid: "0:20010006" }
    ];
    this.DMPTaxonomy = this.buildTree(segments);
  }
};
</script>

<style lang="scss" scoped>
.custom-button {
  margin-top: 41px;
  cursor: pointer;
  margin-left: 20px;
  .custom-button__icon {
    display: inline-flex;
    padding: 4px;
    background: #f0f1f7;
    border-radius: 8px;
  }
}
</style>
