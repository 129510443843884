<template>
  <div>
    <v-row>
      <v-col cols="4" class="my-3 py-0">
        <v-card
          :disabled="currentBidType === 'click'"
          :color="currentBidType === 'click'? 'grey':''"
          outlined
          class="custom-card-info pt-5 pb-5 pl-10 pr-10 font-weight-bold text-center elevation-0"
        >
          <div class="custom-card-info__title" style="font-size: 19px">
            Показы
          </div>
          <div class="custom-card-info__value" style="font-size: 35px">
            <v-text-field
              :value="shows"
              @input="$emit('update:shows',Number($event))"

              label="Показы"
              required
            ></v-text-field>
          </div>
        </v-card>
      </v-col>
      <v-col cols="4" class="my-3 py-0">
        <v-card
          :disabled="currentBidType === 'show'"
          :color="currentBidType === 'show'? 'grey':''"
          outlined
          class="custom-card-info pt-5 pb-5 pl-10 pr-10 font-weight-bold text-center elevation-0"
        >
          <div class="custom-card-info__title" style="font-size: 19px">
            Клики
          </div>
          <div class="custom-card-info__value" style="font-size: 35px">
            <v-text-field
              :value="clicks"
              @input="$emit('update:clicks',Number($event))"

              label="Клики"
              required
            ></v-text-field>
          </div>
        </v-card>
      </v-col>
      <v-col cols="4" class="my-3 py-0">
        <v-card
          outlined
          class="custom-card-info pt-5 pb-5 pl-10 pr-10 font-weight-bold text-center elevation-0"
        >
          <div class="custom-card-info__title" style="font-size: 19px">
            Частота
          </div>
          <div class="custom-card-info__value" style="font-size: 35px">
            <v-text-field
              :value="frequency"
              @input="$emit('update:frequency',Number($event))"

              label="Частота"
              required
            ></v-text-field>
          </div>
        </v-card>
      </v-col>
      <v-col cols="4" class="my-3 py-0">
        <v-card
          :color="currentBidType === 'click'? 'grey':''"
          :disabled="currentBidType === 'click'"
          outlined
          class="custom-card-info pt-5 pb-5 pl-10 pr-10 font-weight-bold text-center elevation-0"
        >
          <div class="custom-card-info__title" style="font-size: 19px">CPM</div>
          <div class="custom-card-info__value" style="font-size: 35px">
            <v-text-field
              :value="cpm"
              @input="$emit('update:cpm',Number($event))"

              label="CPM"
              required
            ></v-text-field>
          </div>
        </v-card>
      </v-col>
      <v-col cols="4" class="my-3 py-0">
        <v-card
          :color="currentBidType === 'show'? 'grey':''"
          :disabled="currentBidType === 'show'"
          outlined
          class="custom-card-info pt-5 pb-5 pl-10 pr-10 font-weight-bold text-center elevation-0"
        >
          <div class="custom-card-info__title" style="font-size: 19px">CPС</div>
          <div class="custom-card-info__value" style="font-size: 35px">
            <v-text-field
              :value="cpc"
              @input="$emit('update:cpc',Number($event))"

              label="CPС"
            ></v-text-field>
          </div>
        </v-card>
      </v-col>
      <v-col cols="4" class="my-3 py-0">
        <v-card
          outlined
          class="custom-card-info pt-5 pb-5 pl-10 pr-10 font-weight-bold text-center elevation-0"
        >
          <div class="custom-card-info__title" style="font-size: 19px">CTR</div>
          <div class="custom-card-info__value" style="font-size: 35px">
            <v-text-field
              :value="ctr"
              @input="$emit('update:ctr',Number($event))"

              label="CTR"
            ></v-text-field>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'TraderCalc',
  props: {
    form: {
      type: Object,
      required: false
    },
    shows: Number,
    clicks: Number,
    frequency: Number,
    cpm: Number,
    cpc: Number,
    ctr: Number,
  },
  computed: {
    currentBidType() {
      return this.form?.calc?.bidType === 'show' ? 'show' : 'click';
    }
  },
  watch: {
    showsLocal(value) {
      this.form.calc_raw.shows = value;
    },
    clicksLocal(value) {
      this.form.calc_raw.clicks = value;
    },
    frequencyLocal(value) {
      this.form.calc_raw.frequency = value;
    },
    cpmLocal(value) {
      this.form.calc_raw.cpm = value;
    },
    cpcLocal(value) {
      this.form.calc_raw.cpc = value;
    },
    ctrLocal(value) {
      this.form.calc_raw.ctr = value;
    },
  },
  data: () => ({
    rules: [
      value => !!value || 'Необходимо заполнить это поле'
    ],
  })
}

</script>

<style scoped>

</style>