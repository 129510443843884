<template>
  <v-skeleton-loader :loading="syncLoading" type="card">
    <v-card outlined>
      <div class="pa-8">
        <div class="d-flex align-center justify-start mb-4">
          <div class="text-h5 font-weight-bold mr-6" style="color: #252733">
            Период
          </div>
          <v-radio-group
            v-if="!isExclusive.enabled"
            :value="typeForecast"
            @change="$emit('update:typeForecast', $event)"
            class="ma-0 pa-0"
            row
            column
            hide-details
          >
            <v-radio color="accent" label="На бронь" value="all"></v-radio>
            <v-radio color="accent" label="На флайт" value="flights"></v-radio>
          </v-radio-group>
        </div>

        <v-row>
          <v-col>
            <custom-date-picker
              :disabled="!isEdit && !isNew"
              label="От"
              v-model="from"
              @input="recalcPeriod"
            ></custom-date-picker>
          </v-col>
          <v-col>
            <custom-date-picker
              :disabled="!isEdit && !isNew"
              label="До"
              v-model="to"
              @input="recalcPeriod"
            ></custom-date-picker>
          </v-col>
        </v-row>

        <template v-if="typeForecast === 'flights'">
          <div v-if="flights.length > 0">
            <v-divider></v-divider>
            <v-card-title>
              Флайты
            </v-card-title>

            <v-row v-for="(flight, key) in flights" :key="key">
              <v-col>
                <custom-date-picker
                  :disabled="!isEdit && !isNew"
                  label="От"
                  v-model="flights[key].date_start"
                  @input="updateDateHandler"
                ></custom-date-picker>
              </v-col>
              <v-col>
                <custom-date-picker
                  :disabled="!isEdit && !isNew"
                  label="До"
                  v-model="flights[key].date_end"
                  @input="updateDateHandler"
                ></custom-date-picker>
              </v-col>
            </v-row>
            <v-row v-if="flights.length">
              <span class="custom-button-icon" @click="addFlightHandler">
                <v-icon medium color="#9FA2B4">mdi-plus</v-icon>
              </span>
            </v-row>
          </div>
        </template>
      </div>
    </v-card>
  </v-skeleton-loader>
</template>

<script>
/* eslint-disable */
import customDatePicker from "@/components/common/CustomDatePicker";
import { AUTH_ABOUT_ME } from "@/store/const/auth";
import requestControl from "@/mixins/requestControl";
import { buildFlights, formatDate } from "@/plugins/helpers";
import { GET_ORDER, FORECAST_TYPE_RADIO_BUTTON_ACTIVE } from "@/store/const/orders";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "Period",
  components: {
    customDatePicker
  },
  mixins: [requestControl],
  props: {
    form: {
      type: Object,
      required: true
    },
    isNew: {
      type: Boolean,
      required: false
    },
    isEdit: {
      type: Boolean,
      required: false
    },
    isDisabled: {
      type: Boolean,
      required: true
    },
    typeForecast: {
      type: String,
      required: true,
    },
    currentFlights: {
      type: Number,
      required: false
    },
    flights: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    },
    dates: {
      type: Object,
      required: true
    },
    isExclusive: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      current: this.currentFlights,
      from: "",
      to: "",
      activeRadio: 0
    };
  },
  computed: {
    ...mapGetters("Orders", {
      orderWasDeleted: 'orderWasDeleted',
      syncLoading: 'getOrderSyncLoading'
    }),
    isLoading() {
      return (
        this.loading(AUTH_ABOUT_ME) === "loading" ||
        this.loading(GET_ORDER) === "loading"
      );
    },
    itsFlightForecast() {
      return this.activeRadio === 1
    }
  },
  mounted() {
    this.updateDefaultDates(this.flights);
  },
  watch: {
    flights: {
      deep: true,
      handler(newVal) {
        this.updateDefaultDates(newVal);
      }
    },
    current() {
      this.$emit("update:currentFlights", this.current);
    },
    dates(val) {
      this.from = val.from;
      this.to = val.to;
    },

  },
  methods: {

    recalcPeriod() {
      if (this.from != "" && this.to != "")
        this.$emit("update:flights", buildFlights({
          from: this.from || '', to: this.to || ''
        }));
        this.$emit("update:dates", { from: this.from || '', to: this.to || '' });
    },
    updateDefaultDates(newVal) {
      if (this.flights.length != 0) {
        this.from = newVal[0].date_start;
        this.to = newVal[newVal.length - 1].date_end;
      }
    },
    addFlightHandler() {
      if (this.flights[this.flights.length - 1].date_end === '') {
        this.$notify({
          type: 'warn',
          text: 'Необходимо заполнить новый флайт'
        })
        return
      } else {
        const _dateEndLast = new Date(this.flights[this.flights.length - 1].date_end);
        const _nextDay = new Date(_dateEndLast);
        _nextDay.setDate(_dateEndLast.getDate() + 1);
        const _nextDayStart = formatDate(_nextDay).split('-').reverse().join('-');
        const _newFlight = { date_start: _nextDayStart, date_end: '' }
        this.flights.push(_newFlight);
      }
    },
    updateDateHandler() {
      let _filteredFlights = [];
      const _emptyFlight = this.flights.find(flight => flight['date_start'] === '' && flight['date_end'] === '');
      if (_emptyFlight !== undefined) {
        _filteredFlights = this.flights.filter(flight => flight['date_start'] !== '' && flight['date_end'] !== '');
        this.$emit('update:flights', _filteredFlights);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.custom-button-icon {
  font-size: 14px;
  font-weight: 600;
  color: #c5c7cd;
  cursor: pointer;
  margin-left: 12px;
  display: inline-flex;
  padding: 4px;
  background: #f0f1f7;
  border-radius: 8px;
}
</style>
