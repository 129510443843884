var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"custom-select"},[_c('div',{staticClass:"custom-select__title custom-select__title--main"},[_vm._v(" Страны ")]),_c('v-autocomplete',{staticClass:"custom-select__field pa-0 ma-0",attrs:{"disabled":!_vm.isEdit && !_vm.isNew,"value":_vm.countries,"clearable":true,"items":_vm.countryDictionary,"deletable-chips":true,"item-text":"name","item-value":"id","multiple":"","hide-details":""},on:{"input":function($event){return _vm.selectCountryHandler($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index <= 1)?_c('v-chip',{attrs:{"input-value":item.selected}},[_c('span',{staticClass:"text-truncate",staticStyle:{"font-size":"12px","max-width":"110px"}},[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.countries.length - 2)+" others) ")]):_vm._e()]}}])})],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"custom-select"},[_c('div',{staticClass:"custom-select__title custom-select__title--main"},[_vm._v("Регионы")]),_c('v-autocomplete',{staticClass:"custom-select__field pa-0 ma-0",attrs:{"disabled":!_vm.isEdit && !_vm.isNew,"value":_vm.regions,"clearable":true,"items":_vm.regionDictionary,"deletable-chips":true,"item-text":"name","item-value":"id","multiple":"","hide-details":""},on:{"input":function($event){return _vm.selectRegionHandler($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index <= 1)?_c('v-chip',{attrs:{"input-value":item.selected}},[_c('span',{staticClass:"text-truncate",staticStyle:{"font-size":"12px","max-width":"110px"}},[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.regions.length - 2)+" others) ")]):_vm._e()]}}])})],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"custom-select"},[_c('div',{staticClass:"custom-select__title custom-select__title--main"},[_vm._v("Города")]),_c('v-autocomplete',{attrs:{"disabled":!_vm.isEdit &&
            !_vm.isNew &&
            (_vm.countries.length === 0 || _vm.regions.length === 0),"value":_vm.cities,"clearable":true,"items":_vm.cityDictionary,"deletable-chips":true,"item-text":"name","item-value":"id","multiple":""},on:{"input":function($event){return _vm.selectCityHandler($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [(index <= 1)?_c('v-chip',{attrs:{"input-value":item.selected}},[_c('span',{staticClass:"text-truncate",staticStyle:{"font-size":"12px","max-width":"110px"}},[_vm._v(" "+_vm._s(item.name)+" ")])]):_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.cities.length - 2)+" others) ")]):_vm._e()]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }