<template>
  <div>
    <div>
      <v-row>
        <v-col cols="4" class="my-3 py-0">
          <v-card
            outlined
            class="custom-card-info pt-5 pb-5 font-weight-bold text-center elevation-0"
          >
            <div class="custom-card-info__title" style="font-size: 19px">
              Показы
            </div>

            <div class="custom-card-info__value" style="font-size: 35px">
              <v-progress-circular
                indeterminate
                v-if="isForecastLoading"
              ></v-progress-circular>
              <span v-else>{{ formatedShows }}</span>
            </div>
          </v-card>
        </v-col>
        <v-col cols="4" class="my-3 py-0">
          <v-card
            outlined
            class="custom-card-info pt-5 pb-5 font-weight-bold text-center elevation-0"
          >
            <div class="custom-card-info__title" style="font-size: 19px">
              Охват
            </div>
            <div class="custom-card-info__value" style="font-size: 35px">
              <v-progress-circular
                indeterminate
                v-if="isForecastLoading"
              ></v-progress-circular>
              <span v-else>{{ formatedReach }}</span>
            </div>
          </v-card>
        </v-col>
        <v-col cols="4" class="my-3 py-0">
          <v-card
            outlined
            class="custom-card-info pt-5 pb-5 font-weight-bold text-center elevation-0"
          >
            <div class="custom-card-info__title" style="font-size: 19px">
              Клики
            </div>
            <div class="custom-card-info__value" style="font-size: 35px">
              <v-progress-circular
                indeterminate
                v-if="isForecastLoading"
              ></v-progress-circular>
              <span v-else> {{ formatedClicks }}</span>
            </div>
          </v-card>
        </v-col>
        <v-col cols="4" class="my-3 py-0">
          <v-card
            outlined
            class="custom-card-info pt-5 pb-5 font-weight-bold text-center elevation-0"
          >
            <div class="custom-card-info__title" style="font-size: 19px">
              CPM
            </div>
            <div class="custom-card-info__value" style="font-size: 35px">
              <v-progress-circular
                indeterminate
                v-if="isForecastLoading"
              ></v-progress-circular>
              <span v-else> {{ formatedCpm }}</span>
            </div>
          </v-card>
        </v-col>
        <v-col cols="4" class="my-3 py-0">
          <v-card
            outlined
            class="custom-card-info pt-5 pb-5 font-weight-bold text-center elevation-0"
          >
            <div class="custom-card-info__title" style="font-size: 19px">
              CPT
            </div>
            <div class="custom-card-info__value" style="font-size: 35px">
              <v-progress-circular
                indeterminate
                v-if="isForecastLoading"
              ></v-progress-circular>
              <span v-else> {{ formatedCpt }}</span>
            </div>
          </v-card>
        </v-col>
        <v-col cols="4" class="my-3 py-0">
          <v-card
            outlined
            class="custom-card-info pt-5 pb-5 font-weight-bold text-center elevation-0"
          >
            <div class="custom-card-info__title" style="font-size: 19px">
              CPC
            </div>
            <div class="custom-card-info__value" style="font-size: 35px">
              <v-progress-circular
                indeterminate
                v-if="isForecastLoading"
              ></v-progress-circular>
              <span v-else> {{ formatedCpc }}</span>
            </div>
          </v-card>
        </v-col>
        <v-col cols="4" class="my-3 py-0">
          <v-card
            outlined
            class="custom-card-info pt-5 pb-5 font-weight-bold text-center elevation-0"
          >
            <div class="custom-card-info__title" style="font-size: 19px">
              Бюджет до НДС
            </div>
            <div
              class="custom-card-info__value"
              style="font-size: 35px"
              v-if="isForecastLoading"
            >
              <v-progress-circular
                indeterminate
                v-if="isForecastLoading"
              ></v-progress-circular>
            </div>
            <div class="custom-card-info__value" v-else style="font-size: 35px">
              <v-text-field
                v-if="!budgetDisabled"
                prepend-icon="mdi-pencil"
                @click:prepend="budgetDisabled = true"
                :value="calculatedBudget"
                type="number"
                @input="updateBudgetOnInput"
                hide-details
                class="text-field-input"
              ></v-text-field>
              <v-tooltip v-else bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="text-field-text"
                    @click="budgetDisabled = false"
                  >
                    <v-icon style="position: relative; margin-bottom: 1%"
                      >mdi-pencil</v-icon
                    >
                    {{ formatedBudget }}
                  </span>
                </template>
                Задать вручную
              </v-tooltip>
            </div>

            <v-slider
              :max="defaultCalcResult.budget"
              :value="calculatedBudget"
              @input="updateBudgetOnInput"
              :min="0"
              style="position: absolute; bottom: -25px; width: 100%;"
            ></v-slider>
          </v-card>
        </v-col>
        <v-col cols="4" class="my-3 py-0">
          <v-card
            outlined
            class="custom-card-info pt-5 pb-5 font-weight-bold text-center elevation-0"
          >
            <div class="custom-card-info__title" style="font-size: 19px">
              Частота
            </div>
            <div class="custom-card-info__value" style="font-size: 35px">
              <v-progress-circular
                indeterminate
                v-if="isForecastLoading"
              ></v-progress-circular>
              <span v-else> {{ form.calc.frequency }}</span>
            </div>
          </v-card>
        </v-col>
        <v-col cols="4" class="my-3 py-0">
          <v-card
            outlined
            class="custom-card-info pt-5 pb-5 font-weight-bold text-center elevation-0"
          >
            <div class="custom-card-info__title" style="font-size: 19px">
              CTR %
            </div>
            <div class="custom-card-info__value" style="font-size: 35px">
              <v-progress-circular
                indeterminate
                v-if="isForecastLoading"
              ></v-progress-circular>
              <span v-else> {{ defaultCalcResult.ctr }}</span>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-btn
      rounded
      x-large
      color="secondary"
      style="text-transform: none"
      class="elevation-0 mt-6"
      @click="copyInfo"
    >
      Копировать результат
    </v-btn>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import dbg from "@/plugins/dbg";
import { GET_ORDER } from "@/store/const/orders";
import { GET_USERS } from "@/store/const/users";
import requestControl from "@/mixins/requestControl";
import { debounce } from "@/plugins/helpers";

export default {
  name: "Response",
  props: {
    form: {
      type: Object,
      required: false
    },
    calc: {
      type: Object,
      required: true
    },
    calc_result: {
      type: Object,
      required: true
    },

  },
  mixins: [requestControl],
  data() {
    return {
      budgetDisabled: true,
      budgetTextFieldType: "text",
      isSyncLoading: false,
      calculatedBudget: null
    };
  },

  computed: {
    allInfo() {
      let dataArr = [
        `Показы: ${this.formatedShows}`,
        `Охват: ${this.formatedReach}`,
        `Клики: ${this.formatedClicks}`,
        `CPM: ${this.formatedCpm}`,
        `CPT: ${this.formatedCpt}`,
        `CPC: ${this.formatedCpc}`,
        `Бюджет до НДС: ${this.formatedBudget}`,
        `Частота: ${this.form.calc.frequency}`,
        `CTR %: ${this.defaultCalcResult.ctr}`,
      ]
      return dataArr;
    },
    isLoading() {
      return (
        this.loading(GET_ORDER) === "loading" ||
        this.loading(GET_USERS) === "loading" ||
        this.getOrderSyncLoading
      );
    },
    isForecastLoading() {
      return this.isForecastUpdating || this.isSyncLoading;
    },
    ...mapState("Orders", ["defaultCalcResult", "formulas"]),
    ...mapState("LiteCalc", { isForecastUpdating: "responseloading" }),
    ...mapGetters("Orders", [
      "getCalcResult",
      "orderWasDeleted",
      "getOrderSyncLoading"
    ]),
    priceLabel() {
      if (this.calc.bidType === "show") return "CPM";
      if (this.calc.format !== "vpaid") return "CPC";
      return "CPV";
    },
    ctrLabel() {
      if (this.calc.format !== "vpaid") return "CTR";
      return "VTR";
    },
    formatedShows() {
      const _shows = isNaN(this.calc_result.shows)
        ? "0"
        : this.calc_result.shows;
      return this.numberWithSpaces(_shows);
    },
    formatedReach() {
      const _reach = isNaN(this.calc_result.reach)
        ? "0"
        : this.calc_result.reach;
      return this.numberWithSpaces(_reach);
    },
    formatedClicks() {
      const _clicks = isNaN(this.calc_result.clicks)
        ? "0"
        : this.calc_result.clicks;
      return this.numberWithSpaces(_clicks);
    },
    formatedCpm() {
      const _cpm = isNaN(this.calc_result.cpm) ? "0" : this.calc_result.cpm;
      return this.numberWithSpaces(_cpm);
    },
    formatedCpt() {
      const _cpt = isNaN(this.calc_result.cpt) ? "0" : this.calc_result.cpt;
      return this.numberWithSpaces(_cpt);
    },
    formatedCpc() {
      const _cpc = isNaN(this.calc_result.cpc) ? "0" : this.calc_result.cpc;
      return this.numberWithSpaces(_cpc);
    },
    formatedBudget() {
      const _budget = this.calculatedBudget;
      return this.numberWithSpaces(_budget);
    }
  },
  watch: {
    defaultCalcResult: {
      deep: true,
      immediate: true,

      handler() {
        this.updateBudget(this.defaultCalcResult.budget);
      }
    }
  },

  methods: {
    objToString(obj) {
      var str = "";
      for (var p in obj) {
        if (obj.hasOwnProperty(p)) {
          str += p + ": " + obj[p] + ";\n";
        }
      }
      return str;
    },
    copyInfo() {
      let res = this.$clipboard(this.allInfo.join(' \n'));
      if (res) {
        this.$notify({
          type: "succ",
          title: "Успешно скопированно"
        });
      } else {
        this.$notify({
          type: "err",
          title: "Ошибка",
          text: res
        });
      }
    },

    updateBudgetOnInput: debounce(function(budget, forceUpdate = false) {
      let $budget_new = Number(budget);
      if ($budget_new === 0 || isNaN($budget_new)) {
        if (this.isLoading === true) {
          return false;
        }
        this.$notify({
          type: "err",
          text: "Бюджет не может быть равен 0"
        });
        return false;
      }
      // если нет изменения в бюджете
      if (budget == this.calculatedBudget && !forceUpdate) {
        return false;
      }
      if ($budget_new <= this.defaultCalcResult.budget) {
        let $budget = this.defaultCalcResult.budget;
        let $ctr = this.defaultCalcResult.ctr;
        let $budget_percent = eval(this.formulas?.after_change?.budget_percent); // "$budget_new / ($budget/100);"

        let $change_percent = +eval(
          "Math." + this.formulas?.after_change?.change_percent
        ); // "sqrt( 100 * $budget_percent);"
        const updatedCalc_result = JSON.parse(JSON.stringify(this.calc_result));
        const _calcBidType = this.calc?.bidType;

        let $shows, $clicks, $cpm, $cpc;
        $budget = $budget_new;
        if (_calcBidType === "show") {
          let $cpm_min = this.defaultCalcResult.min_price;
          let $cpm_max = this.defaultCalcResult.cpm;
          $cpm = +eval(this.formulas?.after_change?.cpm); // "($cpm_max - $cpm_min) * ($change_percent / 100) + $cpm_min;"
          $shows = +eval(this.formulas?.after_change?.shows_from_cpm); // $budget / ($cpm / 1000)
        } else {
          let $cpc_min = this.defaultCalcResult.min_price;
          let $cpc_max = this.defaultCalcResult.price;
          $cpc = +eval(this.formulas?.after_change?.cpc); // "($cpc_max - $cpc_min) * ($change_percent/100)+$cpc_min;"
          $clicks = +eval(this.formulas?.after_change?.clicks_from_cpc); // "$budget / $cpc;"
          $shows = eval(this.formulas?.after_change?.shows_from_cpc); // "$clicks/($ctr/100);"
        }
        let $frequency = Number(this.calc.frequency);
        $clicks = $clicks ? $clicks : eval(this.formulas.clicks);
        let $reach = +eval(this.formulas.reach);
        $cpm = _calcBidType === "show" ? $cpm : +eval(this.formulas.cpm);
        let $cpt = +eval(this.formulas.cpt);
        $cpc = _calcBidType !== "show" ? $cpc : +eval(this.formulas.cpc);
        if (($shows, $reach, $clicks, $cpm, $cpt, $cpc)) {
          updatedCalc_result.shows = $shows.toFixed(0);
          updatedCalc_result.reach = $reach.toFixed(0);
          updatedCalc_result.clicks = $clicks.toFixed(0);
          updatedCalc_result.cpm = $cpm.toFixed(2);
          updatedCalc_result.cpt = $cpt.toFixed(2);
          updatedCalc_result.cpc = $cpc.toFixed(2);
          updatedCalc_result.ctr = $ctr;
          updatedCalc_result.frequency = $frequency;
          updatedCalc_result.budget_percent = $budget_percent;
          updatedCalc_result.budget = budget;
        }
        this.$emit("update:calc_result", updatedCalc_result);
        this.calculatedBudget = budget;
        setTimeout(()=>{
          this.isSyncLoading = false

        },0)

      } else {
        this.$notify({
          type: "err",
          text: `Бюджет не может быть больше ${this.defaultCalcResult.budget.toLocaleString()} (сейчас: ${$budget_new.toLocaleString()})`
        });
        return false;
      }
    }, 128),
    updateBudget() {
      const calc_resultUpdater = {
        ...this.defaultCalcResult
      };
      // расставляем правильный выбраннй бюджет/процентаж от максимального бюджета

      this.$emit(
        "update:calc_result",
        JSON.parse(JSON.stringify(calc_resultUpdater))
      );
      if (!this.calculatedBudget) {
        this.calculatedBudget = (this.calc_result.budget_percent ?? 100) * (this.defaultCalcResult.budget / 100);
      }

      this.isSyncLoading = true
      this.updateBudgetOnInput(this.calculatedBudget, true);
    },
    numberWithSpaces(x) {
      // if isFloat
      if (x % 1 !== 0) {
        x = Number(Number(x).toFixed(2));
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
  }
};
</script>

<style scoped>
.text-field-input {
  font-size: 35px;
  text-align: center;
  width: 50%;
  position: relative;
  left: 55%;
  bottom: 8px;

  transform: translate(-50%);
}
.text-field-text {
  cursor: pointer;
}
</style>
