import { mapActions, mapGetters } from "vuex";
import requestControl from "@/mixins/requestControl";
import {
  UPDATE_ORDER,
  CREATE_ORDER,
  GENERATE_XLS,
  CHECK_EXIST_XLS
} from "@/store/const/orders";
import dbg from "@/plugins/dbg";
import getCalculatorState from "@/mixins/getCalculatorState";
export default {
  mixins: [requestControl, getCalculatorState],

  computed: {
    ...mapGetters("Orders", {
      prices: "getPrices",
      currentOrderID: "getCurrentOrderId",
      excelExist: "getExcelExist",
      defaultCalcResult: "getDefaultCalcResult"
    }),
    ...mapGetters("Auth", { currenUserRole: "myRole" })
  },
  methods: {
    ...mapActions("Orders", {
      updateOrder: UPDATE_ORDER,
      createOrder: CREATE_ORDER,
      generateXLS: GENERATE_XLS,
      checkXLSExist: CHECK_EXIST_XLS
    }),
    validateOrder() {
      let { name, description } = this.form;
      if (name?.length == 0) {
        this.$notify({
          type: "err",
          text: "Введите название брони"
        });

        window.scrollTo({ top: 0, behavior: "smooth" });
        throw new Error("Name");
      }

      if (this.form.calc.exclusive.enabled && !description) {
        this.$notify({
          type: "err",
          text: "Необходимо заполнить поле Описание"
        });

        throw new Error("Description");
      }

      // if (orderCondition || flightsCondition) {
      //   this.$notify({
      //     type: "err",
      //     text: "Перед сохраненим необходимо получить прогноз"
      //   });
      //   throw new Error("Forecast");
      // }
      return true;
    },

    formatDemography() {
      const { calc } = this.form;
      let values = [];
      let isSexSetted = calc.sex[0] || calc.sex[1];
      if (isSexSetted || calc.min !== "" || calc.max !== "") {
        if (calc?.sex[0] && !calc?.sex[1]) {
          values = this.demographySegments("Мужчины");
        } else if (calc?.sex[1] && !calc?.sex[0]) {
          values = this.demographySegments("Женщины");
        } else {
          values = this.demographySegments("Женщины");
          values.push(...this.demographySegments("Мужчины"));
          values.push(...this.demographySegments("Not Defined"));
        }
      }

      return values;
    },
    async sendData() {
      try {
        this.validateOrder();
      } catch (e) {
        dbg("err ", e);
        return 0;
      }
      let data = {};
      data = this.formatRequestData();
      try {
        if (this.isNew) {
          let res = await this.createOrder({
            id: this.$route.params.id,
            data
          });
          if (this.checkRequestOnError(CREATE_ORDER)) {
            this.$notify({
              type: "succ",
              text: `Бронь создана`
            });
            let orderID = res.data.data.id;
            await this.getOrder({ id: orderID });
            await this.generateXlsLink();

            await this.$router.push({
              name: "updateReservation",
              params: { id: this.currentOrderID }
            });
          }
        } else {
          await this.updateOrder({
            id: this.$route.params.id,
            data
          });
          if (this.checkRequestOnError(UPDATE_ORDER)) {
            await this.generateXlsLink();
            this.$notify({
              type: "succ",
              text: `Бронь обновлена`
            });
            document.location.reload();
          }
        }
      } catch (err) {
        dbg("Create order error", err);
      }
    },
    async generateXlsLink() {
      try {
        let _isExistFlightsFileCounter = 0;
        await this.generateXLS({
          id: this.currentOrderID || this.$route.params.id
        });

        const intervalFileHandler = async () => {
          dbg(
            "here",
            _isExistFlightsFileCounter,
            _isExistFlightsFileCounter < 8
          );
          _isExistFlightsFileCounter++;

          await this.checkXLSExist({
            id: this.currentOrderID || this.$route.params.id
          });
          if (!this.excelExist && _isExistFlightsFileCounter < 8) {
            setTimeout(intervalFileHandler, 3000);
          }
        };

        await intervalFileHandler();
      } catch (e) {
        this.$notify({
          type: "err",
          text: "Не удалось сохранить бронь"
        });
        console.log(e);
      }
    },

    formatRequestData() {
      let data = {
        priority: this.form.priority,
        name: this.form.name,
        description: this.form.description || "",
        flights: this.form.flights,
        calc_raw: {
          exclusive: this.form.calc.exclusive,
          trafic: this.form.calc.trafic,
          format: this.form.calc.format,
          bidType: this.form.calc.bidType,
          frequency: this.form.calc.frequency,
          income: this.form.calc.income,
          sex: this.form.calc.sex,
          min: this.form.calc.min,
          max: this.form.calc.max,
          segments: this.form.calc.segments,
          typeForecast: this.form.calc.typeForecast,
          cities: this.form.calc.cities,
          countries: this.form.calc.countries,
          regions: this.form.calc.regions,
          forecastDate: this.form.calc.forecastDate,
          // combo of (sex + min + max)
          demography: this.formatDemography()
        },
        calc_deadline: this.form.calc_deadline,
        exclusive: this.form.calc.exclusive.enabled,
        calc_result: {
          // !important
          budget_percent: this.form.calc_result.budget_percent,
          name: this.defaultCalcResult.name,
          reach: this.defaultCalcResult.reach || 0,
          ctr: this.defaultCalcResult.ctr || 0,
          shows: this.defaultCalcResult.shows || 0,
          max_frequency: this.defaultCalcResult.max_frequency || 0,
          frequency: this.defaultCalcResult?.frequency || 0
        },
        // status: "Approving",
        hidden_from_manager: this.form.hidden_from_manager,
        keepers: [],
        watchers: [],
        // прайс кий отправляется только если получили прогноз
        price_key: this.form.price_key
      };
      // keepers
      if (this.currenUserRole === "trader" && data.exclusive) {
        const { shows, clicks, frequency, cpm, cpc, ctr } = this.calc_trader;
        console.log(data);
        // if CPM
        if (this.form.calc.bidType === "show") {
          data.calc_trader = {
            ctr,
            frequency,
            cpm,
            shows
          };
          //if CPC || COMPLETE
        } else {
          data.calc_trader = {
            ctr,
            frequency,
            cpc,
            clicks
          };
        }
      }
      if (this.form.keepers.account)
        data.keepers.push(this.form.keepers.account);
      if (this.form.keepers.manager)
        data.keepers.push(this.form.keepers.manager);
      if (this.form.keepers.trader) data.keepers.push(this.form.keepers.trader);

      if (this.isNew) data.brand = Number(this.$route.params.id);
      if (!this.isNew) data.id = Number(this.$route.params.id);
      return data;
    }
  }
};
