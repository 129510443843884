import { render, staticRenderFns } from "./index.vue?vue&type=template&id=752fb2bb&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=752fb2bb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "752fb2bb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VContainer,VFabTransition,VIcon,VSkeletonLoader,VTooltip})
