<template>
  <div class="custom-select">
    <div class="custom-select__title custom-select__title--main">Тип закупки</div>
    <v-select
      :disabled="!isEdit && !isNew"
      :value="value"
      @input="$emit('input', $event)"
      :items="campaignTypes"
      menu-props="auto"
      single-line="single-line"
      item-text="name"
      item-value="value"
      hide-details
      class="custom-select__field pa-0 ma-0"
    ></v-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Format",
  props: {
    isNew: {
      type: Boolean,
      required: false
    },
    isEdit: {
      type: Boolean,
      required: false
    },
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      campaignTypes: [
        { name: "Video", value: "vpaid" },
        { name: "Banner", value: "rtb" },
        { name: "Native", value: "teaser" }
      ]
    };
  },
  computed: {
    ...mapGetters("Orders", { orderWasDeleted: "orderWasDeleted" })
  }
};
</script>
