<template>
  <div class="custom-select__wrapper">
    <div class="custom-select__prepend">
      <slot name="prepend" />
    </div>

    <div class="custom-select">
      <v-skeleton-loader
        :loading="syncLoading"
        type="chip"
        class="custom-select__title"
      >
        {{ title }}
      </v-skeleton-loader>
      <v-skeleton-loader :loading="syncLoading" type="table-cell">
        <v-select
          placeholder="Не выбран"
          :disabled="!isEdit && !isNew"
          hide-details
          class="pa-0 ma-0 v-custom-select"
          :items="items"
          item-text="name"
          item-value="id"
          :value="value"
          @input="$emit('update:value', $event)"
        ></v-select>
      </v-skeleton-loader>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SettingsPanelItem",
  props: {
    isNew: {
      type: Boolean,
      required: false
    },
    isEdit: {
      type: Boolean,
      required: false
    },
    value: {
      type: [String, Number],
      required: false
    },
    items: {
      type: Array,
      required: false
    },
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters("Orders", {
      syncLoading: "getOrderSyncLoading"
    })
  }
};
</script>

<style>
.v-custom-select input::placeholder {
  color: black !important;
  font-size: 14px;
  font-weight: bolder;
}
</style>
