var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-skeleton-loader',{attrs:{"loading":_vm.syncLoading,"type":"button button"}},[_c('div',[_c('div',{staticClass:"d-flex align-center forecast-actions-block"},[(_vm.isNew || _vm.isEdit)?[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMainButton),expression:"showMainButton"}],staticClass:"elevation-0 mr-4",staticStyle:{"text-transform":"none"},attrs:{"rounded":"","x-large":"","color":"primary"},on:{"click":_vm.onSendData}},[_vm._v(" "+_vm._s(_vm.isNew ? "Создать бронь" : "Сохранить изменения")+" ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExclusive.enabled && !_vm.showMainButton),expression:"isExclusive.enabled && !showMainButton"}],staticClass:"elevation-0 mr-4",staticStyle:{"text-transform":"none"},attrs:{"rounded":"","x-large":"","color":"primary"},on:{"click":function($event){return _vm.$emit('onSendData')}}},[_vm._v(" "+_vm._s(_vm.isNew ? "Создать нестандартную бронь" : "Сохранить изменения")+" ")]),(_vm.form.status)?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.showMainButton && _vm.form.approved_manager && _vm.form.approved_trader
          ),expression:"\n            showMainButton && form.approved_manager && form.approved_trader\n          "}],staticClass:"elevation-0 mr-4",staticStyle:{"text-transform":"none"},attrs:{"rounded":"","x-large":"","color":"info"},on:{"click":function($event){return _vm.changeStatus(
              _vm.form.status.id === 'Approving' ? 'in-progress' : 'approving'
            )}}},[_vm._v(" "+_vm._s(_vm.form.status.id === "Approving" ? 'Перевести в статус "Согласовано"' : 'Перевести в статус "В процессе"')+" ")]):_vm._e(),(_vm.form.status)?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.form.status.id === 'InProgress' &&
              _vm.showMainButton &&
              _vm.form.approved_manager &&
              _vm.form.approved_trader
          ),expression:"\n            form.status.id === 'InProgress' &&\n              showMainButton &&\n              form.approved_manager &&\n              form.approved_trader\n          "}],staticClass:"elevation-0 mr-4",staticStyle:{"text-transform":"none"},attrs:{"rounded":"","x-large":"","color":"info"},on:{"click":function($event){return _vm.changeStatus('done')}}},[_vm._v(" Перевести в статус \"Завершено\" ")]):_vm._e()]:_vm._e(),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExclusive.enabled === false),expression:"isExclusive.enabled === false"}],staticClass:"elevation-0 mr-10",staticStyle:{"text-transform":"none"},attrs:{"rounded":"","x-large":"","loading":_vm.forecastLoading,"color":"primary"},on:{"click":_vm.submitForecast}},[_vm._v(" Получить прогноз ")]),(
          _vm.isExclusive.enabled &&
            !_vm.isNew &&
            ['root', 'account'].includes(_vm.currentUserRole)
        )?_c('creatives-popup',{attrs:{"type-of-file":"xls"},model:{value:(_vm.uploadDialog),callback:function ($$v) {_vm.uploadDialog=$$v},expression:"uploadDialog"}},[_vm._v(" Загрузить файл ")]):_vm._e(),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExcelExist),expression:"isExcelExist"}],attrs:{"link":true,"plain":true,"disabled":_vm.isXLSLoading || !_vm.isForecastActual,"rounded":"","x-large":"","color":"accent"},on:{"click":_vm.downloadXLS}},[_vm._v(" Скачать xls-файл "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-microsoft-excel")])],1),(_vm.isXLSLoading && _vm.form.typeForecast === 'flights')?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#dddb00"}}):_vm._e()],2),(!_vm.isExclusive.enabled)?_c('div',{staticClass:"my-6"},[_c('v-icon',[_vm._v("mdi-lightbulb")]),_vm._v(" Полученный прогноз является предполагаемым, фактические показатели могут отличаться ")],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }