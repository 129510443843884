<template>
  <v-card outlined>
    <v-row no-gutters>
      <v-col cols="8">
        <calculator-blocks-general-main
          :isEdit="isEdit"
          :isNew="isNew"
          :description="description"
          :name="name"
          :exclusive="exclusive"
          @update:description="$emit('update:description', $event)"
          @update:name="$emit('update:name', $event)"
        >
          <template v-slot:targeting>
            <calculator-general-tabs-targeting
              :isEdit="isEdit"
              :isNew="isNew"
              :trafic="trafic"
              :format="format"
              :bidType="bidType"
              :frequency="frequency"
              :max-frequency="form && form.calc_raw && form.calc_raw.frequency"
              @update:trafic="$emit('update:trafic', $event)"
              @update:format="$emit('update:format', $event)"
              @update:bidType="$emit('update:bidType', $event)"
              @update:frequency="$emit('update:frequency', $event)"
            ></calculator-general-tabs-targeting>
          </template>
          <template v-slot:geo>
            <calculator-general-tabs-geo
              :isEdit="isEdit"
              :isNew="isNew"
              :cities="cities"
              :countries="countries"
              :regions="regions"
              @update:cities="$emit('update:cities', $event)"
              @update:countries="$emit('update:countries', $event)"
              @update:regions="$emit('update:regions', $event)"
            ></calculator-general-tabs-geo>
          </template>
          <template v-slot:socdem>
            <calculator-general-tabs-socdem
              :isEdit="isEdit"
              :isNew="isNew"
              :sex="sex"
              :income="income"
              :min="min"
              :max="max"
              @update:sex="$emit('update:sex', $event)"
              @update:income="$emit('update:income', $event)"
              @update:min="$emit('update:min', $event)"
              @update:max="$emit('update:max', $event)"
            ></calculator-general-tabs-socdem>
          </template>
          <template v-slot:segments>
            <calculator-general-tabs-segments
              v-model="segments"
              :isEdit="isEdit"
              :isNew="isNew"
              :form.sync="form"
            >
            </calculator-general-tabs-segments>
          </template>
        </calculator-blocks-general-main>
        <v-skeleton-loader
          class="checkbox-container pl-8 pb-7"
          :loading="syncLoading"
          type="list-item-avatar"
        >
          <v-checkbox
            :disabled="!isEdit && !isNew"
            v-model="exclusive.enabled"
            @change="$emit('update:exclusive.enabled', $event)"
            color="accent"
            hide-details
            class="exclusive"
          >
            <template v-slot:label>
              <div class="exclusive__wrapper">
                <div class="exclusive__header">
                  <strong class="exclusive__title">Нестандарт</strong>
                  <span class="exclusive__mark"
                    >Бронь рассчитывается индивидуально</span
                  >
                </div>
                <div>
                  <b
                    >Добавьте данные для индивидуального расчета в блок с
                    описанием</b
                  >
                </div>
              </div>
            </template>
          </v-checkbox>
        </v-skeleton-loader>
      </v-col>
      <v-col cols="4" style="border-left: thin solid rgba(0, 0, 0, 0.12)">
        <slot></slot>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
/* eslint-disable */
import calculatorBlocksGeneralMain from "@/components/views/Reservation/components/Calculator/Blocks/General/Main.vue";
import CalculatorGeneralTabsTargeting from "@/components/views/Reservation/components/Calculator/Tabs/Targeting/index";
import CalculatorGeneralTabsGeo from "@/components/views/Reservation/components/Calculator/Tabs/Geo";
import CalculatorGeneralTabsSocdem from "@/components/views/Reservation/components/Calculator/Tabs/Socdem/index";
import CalculatorGeneralTabsSegments from "@/components/views/Reservation/components/Calculator/Tabs/Segments";

import { mapGetters } from "vuex";

export default {
  name: "index",
  components: {
    calculatorBlocksGeneralMain,
    CalculatorGeneralTabsTargeting,
    CalculatorGeneralTabsGeo,
    CalculatorGeneralTabsSocdem,
    CalculatorGeneralTabsSegments
  },
  props: {
    form: {
      type: Object,
      required: false
    },
    name: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    exclusive: {
      type: Object,
      required: false
    },
    segments: {
      type: Array,
      required: false,
      default: () => [[], []]
    },
    trafic: {
      type: String,
      required: false
    },
    format: {
      type: String,
      required: false
    },
    bidType: {
      type: String,
      required: false
    },
    frequency: {
      type: [String, Number],
      required: false
    },
    income: {
      type: Array,
      required: false
    },
    sex: {
      type: [String, Array],
      required: true
    },
    min: {
      type: [String, Number],
      required: false
    },
    max: {
      type: [String, Number],
      required: false
    },
    cities: {
      type: Array,
      required: false
    },
    countries: {
      type: Array,
      required: true
    },
    regions : {
      type: Array,
      required: false
    },

    isNew: {
      type: Boolean,
      required: false
    },
    isEdit: {
      type: Boolean,
      required: false
    },
    demography: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters("Orders", {
      syncLoading: 'getOrderSyncLoading',

      allDemography: "getAllDemography",
      gender: 'getCurrentGender'
    }),
    ...mapGetters("Roles", {
      rolesByName: "getRolesIdsByNames"
    }),
  },
  mounted() {
    if (!this.countries.length) { // RF by default
      this.$emit("update:countries", ['8103149070208538614']);
    }
  },
};
</script>

<style scoped>
.exclusive >>> .v-label {
  flex-grow: 0;
}

.checkbox-container {
  width: 55%;
}
</style>
