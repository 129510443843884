<template>
  <v-data-table
    class="custom-table"
    :headers="headers"
    :items="orderFiles"
    :items-per-page="10"
    :loading="isLoading"
    loader-height="6"
    loading-text="Загрузка списка... Пожалуйста подождите "
    :footer-props="footerProps"
    :hide-default-footer="orderFiles.length <= 10"
  >
    <template #[`item.created_at`]="{ item }">
      <span>{{ new Date(item.created_at).toLocaleString() }}</span>
    </template>
    <template #[`item.options`]="{ item }">
      <v-btn class="mt-1 mr-1 mb-1" small icon fab @click="downloadFile(item)">
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-btn class="ma-1" @click="confirmDelete(item)" small icon fab>
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import { mapActions, mapState } from "vuex";
import requestControl from "@/mixins/requestControl";
import { download } from "@/plugins/helpers";
import {
  GET_ORDER_FILES,
  DOWNLOAD_ORDER_FILE,
  DELETE_ORDER_FILE
} from "../../../store/const/orders";

export default {
  components: {},
  mixins: [requestControl],

  data() {
    return {
      currentEntity: {},
      dialogState: false,
      footerProps: {
        "items-per-page-options": [5, 10, 20, 50, -1],
        "items-per-page-text": "Пользователей на странице:"
      },
      headers: [
        {
          width: "130px",
          text: "Название",
          align: "start",
          value: "name"
        },
        {
          width: "130px",
          text: "Описание",
          align: "start",
          value: "description"
        },
        {
          width: "150px",
          text: "Добавлено",
          align: "start",
          value: "created_at"
        },
        {
          width: "130px",
          text: "Действие",
          align: "start",
          value: "options",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapState("Orders", ["orderFiles"]),
    isLoading() {
      return this.loading(GET_ORDER_FILES) === "loading";
    }
  },
  methods: {
    ...mapActions("Orders", {
      getOrderFile: DOWNLOAD_ORDER_FILE,
      removeFile: DELETE_ORDER_FILE,
      fetchFiles: GET_ORDER_FILES
    }),
    async downloadFile(file) {
      let res = await this.getOrderFile(file);
      download(res.data, file.name);
    },
    async confirmDelete(file) {
      const res = await this.$confirm("Подтвердите удаление", {
        title: "Удаление"
      });
      if (res) {
        await this.removeFile(file);
        if (this.checkRequestOnError(DELETE_ORDER_FILE)) {
          this.$notify({
            type: "succ",
            text: "Креатив успешно удален"
          });
          this.fetchFiles({ id: this.$route.params.id });
        }
      }
    }
  }
};
</script>

<style scoped>
.custom-table >>> .v-data-table-header th.sortable {
  white-space: nowrap;
}
</style>
