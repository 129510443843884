<template>
  <v-row>
    <v-col cols="6">
      <device-type
        :isEdit="isEdit"
        :isNew="isNew"
        :value="trafic"
        @input="$emit('update:trafic', $event)"
      ></device-type>
    </v-col>
    <v-col cols="6">

      <buying-type
        :isEdit="isEdit"
        :isNew="isNew"
        :value="bidType"
        :format="format"
        @input="$emit('update:bidType', $event)"
      ></buying-type>
    </v-col>
    <v-col cols="6">
      <format
        :isEdit="isEdit"
        :isNew="isNew"
        :value="format"
        @input="$emit('update:format', $event)"
      ></format>
    </v-col>
    <v-col cols="6">
      <frequency
        :isEdit="isEdit"
        :isNew="isNew"
        :value="frequency"
        :calc-raw-frequency="maxFrequency"
        @input="$emit('update:frequency', $event)"
      ></frequency>
    </v-col>
  </v-row>
</template>

<script>
import DeviceType from "@/components/views/Reservation/components/Calculator/Tabs/Targeting/DeviceType";
import BuyingType from "@/components/views/Reservation/components/Calculator/Tabs/Targeting/BuyingType";
import Format from "@/components/views/Reservation/components/Calculator/Tabs/Targeting/Format";
import Frequency from "@/components/views/Reservation/components/Calculator/Tabs/Targeting/Frequency";

export default {
  name: "Targeting",
  components: {
    DeviceType,
    BuyingType,
    Format,
    Frequency
  },
  props: {
    isNew: {
      type: Boolean,
      required: false
    },
    isEdit: {
      type: Boolean,
      required: false
    },
    trafic: {
      type: String,
      required: false
    },
    format: {
      type: String,
      required: false
    },
    bidType: {
      type: String,
      required: false
    },
    frequency: {
      type: [String, Number],
      required: false
    },
    maxFrequency: {
      type: [String, Number],
      required: false
    }
  },
  watch:{
    format(newVal){
      if(newVal === 'vpaid' && this.bidType === 'click'){
        this.$emit('update:bidType', 'complete')
      }else if(['teaser','rtb'].includes(newVal) && this.bidType === 'complete'){
        this.$emit('update:bidType', 'click')
      }
    }

  },

  data() {
    return {
      items: ["test"]
    };
  }
};
</script>
