<template>
  <div class="pl-8 pr-12 py-7" id="order-description">
    <v-skeleton-loader
      v-if="isEdit || isNew"
      height="50"
      :loading="syncLoading"
      type="image"
    >
      <v-text-field
        :disabled="!isEdit && !isNew"
        :value="name"
        :rules="[orderNameRules.required]"
        @input="$emit('update:name', $event)"
        outlined
        label="Название"
        hide-details="auto"
        class="mb-3"
      ></v-text-field>
    </v-skeleton-loader>
    <v-skeleton-loader :loading="syncLoading" type="image">
      <v-textarea
        :disabled="!isEdit && !isNew"
        :rules="exclusive.enabled ? [orderDescriptionRules.required] : []"
        :value="description"
        @input="$emit('update:description', $event)"
        outlined
        name="input-7-4"
        label="Описание"
      ></v-textarea>
    </v-skeleton-loader>
    <v-skeleton-loader class="mt-4" :loading="syncLoading" type="sentences">
      <div class="text-h5 font-weight-bold" style="color: #252733">
        Калькулятор
      </div>
      <p>Заполните данные и выполните расчет</p>
    </v-skeleton-loader>
    <v-skeleton-loader
      class="mt-4"
      height="230"
      :loading="syncLoading"
      type="table"
    >
      <v-tabs v-model="tab" class="tabs-reservation">
        <v-tab v-for="item in tabs" :key="item.key">{{ item.title }}</v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabs" :key="item.key">
            <slot :name="item.key"></slot>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-skeleton-loader>
  </div>
</template>

<script>
/* eslint-disable */
import validationRules from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  name: "mainPanel",
  mixins: [validationRules],
  props: {
    name: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    isNew: {
      type: Boolean,
      required: false
    },
    isEdit: {
      type: Boolean,
      required: false
    },
    exclusive: {
      type: Object,
      required: false
    },
  },
  data() {
    return {
      textarea: "",
      tab: 0,
      orderNameRules: {
        required: value => !!value || 'Обязательное поле'
      },
      orderDescriptionRules: {
        required: value => !!value || 'Обязательное поле'
      },
      tabs: [
        {
          title: "Таргетинг",
          key: "targeting"
        },
        {
          title: "ГЕО",
          key: "geo"
        },
        {
          title: "Соц.дем",
          key: "socdem"
        },
        {
          title: "Интересы / Сегменты аудитории",
          key: "segments"
        }
      ]
    };
  },
  computed:{
    ...mapGetters('Orders',{
      syncLoading: 'getOrderSyncLoading',
    })
  }
};
</script>

<style scoped></style>
