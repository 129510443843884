<template>
  <v-menu
    style="background: #fff"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="!readonly ? on : ''">
        <v-text-field
          outlined
          :dense="dense"
          :value="value"
          hide-details="auto"
          @input="changeDate($event)"
          :label="label"
          prepend-inner-icon="mdi-calendar"
          readonly
          clearable
          :disabled="disabled"
        ></v-text-field>
      </div>
    </template>
    <v-date-picker
      scrollable
      :min="min"
      :max="max"
      :disabled="disabled"
      :readonly="readonly"
      :first-day-of-week="1"
      :value="value"
      :range="range"
      @input="changeDate($event)"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "CustomDatePicker",
  props: {
    value: {
      type: [Date, String, Number],
      required: true,
      default: ""
    },
    range: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: "Дата"
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },

    min: {
      type: [String, undefined],
      required: false,
      default: null
    },
    max: {
      type: [String, undefined],
      required: false,
      default: null
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      menu: false
    };
  },
  methods: {
    changeDate(event) {
      if (event === null) this.$emit("input", "");
      else this.$emit("input", event);
    }
  }
};
</script>

<style></style>
