<template>
  <v-container
    class=" flex-nowrap flex-column"
    fluid="fluid"
    style="align-items: normal"
    id="order-page"
  >
    <!-- <float-actions :form="form" :is-new="isNew"></float-actions> -->
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-fab-transition>
          <v-btn
            v-if="isNew"
            v-bind="attrs"
            v-scroll-to="'#order-description'"
            v-on="on"
            class="helper-button"
            color="accent"
            fab
            small
            dark
            top
            right
          >
            <v-icon small>mdi-help</v-icon>
          </v-btn>
        </v-fab-transition>
      </template>
      <span>Не нашли что ищете?</span>
    </v-tooltip>
    <div class="mx-3">
      <view-toolbar
        v-if="$route.params.id"
        :title="isNew ? 'Создание брони' : `Бронь №${orderNumber}`"
      >
        <template #back-buttons>
          <router-link
            class="back-button mr-4"
            :to="{
              name: 'reservation'
            }"
          >
            <v-icon dense>mdi-arrow-left</v-icon>
          </router-link>
        </template>
        <template #buttons></template>
      </view-toolbar>
      <div v-if="!isNew" style="margin-top: -40px;">
        <div
          v-if="!isEdit && !isNew"
          style="display:flex; align-items: center;"
        >
          <v-skeleton-loader
            width="150px"
            :loading="syncLoading"
            type="heading"
          >
            <h4>{{ form.name }}</h4>
          </v-skeleton-loader>
          <v-btn
            @click="onEditHandler"
            data-cy="delete"
            icon="icon"
            style="margin-left: 5px;"
          >
            <v-icon color="black">mdi-pencil</v-icon>
          </v-btn>
        </div>

        <div class="mt-2 mb-7" style="display:flex;;font-size: 12px;">
          <v-skeleton-loader width="150px" :loading="syncLoading" type="text">
            <div>
              <span style="color: #9FA2B4;">Дата создания: </span>
              <span>{{ dateFormate(form.created_at) }}</span>
            </div>
          </v-skeleton-loader>
          <v-skeleton-loader
            width="150px"
            class="ml-5"
            :loading="syncLoading"
            type="text"
          >
            <div style="">
              <span style="color: #9FA2B4;">Обновлено: </span>
              <span>{{ dateFormate(form.updated_at) }}</span>
            </div>
          </v-skeleton-loader>
        </div>
      </div>
      <calculator-blocks-general
        :form.sync="form"
        :isEdit="isEdit"
        :isNew="isNew"
        v-bind.sync="form.calc"
        :description.sync="form.description"
        :name.sync="form.name"
        :sex.sync="form.calc.sex"
        :min.sync="form.calc.min"
        :max.sync="form.calc.max"
        :demography="form.calc.demography"
      >
        <calculator-blocks-general-settings-panel
          :status.sync="isNew ? 'New' : form.status.id"
          @update:status="updateStatus"
          :is-disabled="true"
          v-bind.sync="form"
          :calc_deadline.sync="form.calc_deadline"
          :isEdit="isEdit"
          :isNew="isNew"
          :keepersList="keepers"
          :form.sync="form"
        >
        </calculator-blocks-general-settings-panel>
      </calculator-blocks-general>

      <div
        style="margin: 24px 0; display: flex; justify-content: space-between"
      >
        <calculator-blocks-period
          :form.sync="form"
          :isEdit="isEdit"
          :isNew="isNew"
          style="width: calc(50% - 12px)"
          :dates.sync="form.dates"
          :is-disabled="isDisabled"
          :flights.sync="form.flights"
          :typeForecast.sync="form.calc.typeForecast"
          :currentFlights.sync="form.currentFlights"
          :is-exclusive="form.calc.exclusive"
        ></calculator-blocks-period>
        <calculator-blocks-creatives
          :isEdit="isEdit"
          :isNew="isNew"
          style="width: calc(50% - 12px)"
        ></calculator-blocks-creatives>
      </div>
      <!-- CALC_ACTIONS -->
      <calculator-blocks-actions
        class="mt-2"
        :form.sync="form"
        :is-new="isNew"
        :is-edit.sync="isEdit"
        :is-exclusive="form.calc.exclusive"
        :new-order-id="newOrderId"
        @onSendData="sendData"
      ></calculator-blocks-actions>
      <!--   calc_trader FIELDS  -->
      <calculator-blocks-trader-calc
        v-if="traderCalcDisplayRules"
        :form.sync="form"
        v-bind.sync="calc_trader"
      ></calculator-blocks-trader-calc>

      <!-- CALC_RESPONSE -->
      <calculator-blocks-response
        v-if="isPriceExist"
        :calc.sync="form.calc"
        :form.sync="form"
        :calc_result.sync="form.calc_result"
      ></calculator-blocks-response>
    </div>
  </v-container>
</template>

<script>
/* eslint-disable */
// import calc from "@/components/views/TempCalc/index";
import { AUTH_ABOUT_ME } from "@/store/const/auth";
import { GET_USERS } from "@/store/const/users";
import requestControl from "@/mixins/requestControl";
import { GET_DEMOGRAPHY, GET_FORMULAS, GET_ORDER } from "@/store/const/orders";

import { mapActions, mapGetters } from "vuex";

import viewToolbar from "@/components/common/ViewToolbar";
import calculatorBlocksGeneral from "@/components/views/Reservation/components/Calculator/Blocks/General/index.vue";
import calculatorBlocksPeriod from "@/components/views/Reservation/components/Calculator/Blocks/Period.vue";
import calculatorBlocksCreatives from "@/components/views/Reservation/components/Calculator/Blocks/Creatives.vue";
import calculatorBlocksResponse from "@/components/views/Reservation/components/Calculator/Blocks/Response.vue";
import calculatorBlocksTraderCalc from "@/components/views/Reservation/components/Calculator/Blocks/TraderCalc";
import calculatorBlocksActions from "@/components/views/Reservation/components/Calculator/Blocks/Actions.vue";
import calculatorBlocksGeneralSettingsPanel from "@/components/views/Reservation/components/Calculator/Blocks/General/SettingsPanel.vue";
import { GET_GEOTAXONOMY } from "@/store/const/helpers";
import axios from "axios";
import {
  CHECK_EXIST_XLS,
  GENERATE_XLS,
  DOWNLOAD_ORDER_XLS
} from "@/store/const/orders";
import updateOrder from "@/mixins/updateOrder";
import dbg from "@/plugins/dbg";
import { SET_ORDER_STATUS } from "../../../../../store/const/orders";

const defaultCalc = {
  trafic: "all",
  format: "rtb",
  bidType: "show",
  frequency: 0,
  income: [],
  // ['Мужчины', 'Женщины']
  sex: [false, false],
  // минимальный возраст
  min: "",
  // максимальный возраст
  max: "",
  cities: [],
  countries: [],
  regions: [],
  segments: [[], []],
  typeForecast: "all",
  exclusive: {
    enabled: false,
    info: ""
  }
};
export default {
  name: "OrderContainer",
  components: {
    // calc,
    viewToolbar,
    calculatorBlocksGeneral,
    calculatorBlocksPeriod,
    calculatorBlocksCreatives,
    calculatorBlocksResponse,
    calculatorBlocksTraderCalc,
    calculatorBlocksActions,
    calculatorBlocksGeneralSettingsPanel
  },
  props: {
    isNew: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mixins: [requestControl, updateOrder],
  data() {
    return {
      name: "",
      isEdit: false,
      type: "",
      disabled: false,
      withScroll: false,
      search: "",
      calc_trader: {
        shows: 0,
        clicks: 0,
        frequency: 0,
        cpm: 0,
        cpc: 0,
        ctr: 0,
      },
      form: {
        calc_deadline: "",
        currentFlights: 0,
        flights: [],
        hidden_from_manager: false,
        priority: "Normal",
        dates: {
          from: "",
          to: ""
        },
        calc_raw: {},
        calc_result: {

        },
        description: "",
        name: "",
        keepers: {
          manager: "",
          account: "",
          trader: ""
        },
        approved_manager: {},
        approved_trader: {},
        calc: JSON.parse(JSON.stringify(defaultCalc))
      },
      keepers: {
        manager: [],
        account: [],
        trader: []
      },
      newOrderId: null
    };
  },
  async created() {
    this.$store.commit("Orders/setOrderSyncLoading", true);

    await this.getFormulas();

    if (this.$route.query.copyOrder) {
      await this.getOrder({ id: this.$route.query.copyOrder });
      this.form.calc = this.order.calc_raw;
      this.$store.commit("Orders/resetPrevOrderStoreValues");
      await this.getDemography();
    } else {
      this.$store.commit("Orders/resetPrevOrderStoreValues");
      await this.getDemography();
    }
    if (!this.isNew) {
      await this.getCreatedOrder();
      await this.checkXLSExist({ id: this.$route.params.id });
      console.log(this.isExcelExist);
      if (!this.isExcelExist) {


        this.$store.commit(`Orders/${[CHECK_EXIST_XLS]}`, {
          data: { file_exist: true }
        });

      }
    }
    this.getGeo();
    this.getKeepers();

    this.$store.commit("Orders/setOrderSyncLoading", false);
  },

  computed: {
    ...mapGetters("Orders", {
      order: "getOrder",
      isExcelExist: "getExcelExist",
      allDemography: "getAllDemography",
      syncLoading: "getOrderSyncLoading"
    }),
    ...mapGetters("Roles", {
      rolesByName: "getRolesIdsByNames"
    }),
    ...mapGetters("Auth", ["getUser"]),
    isLoading() {
      return (
        this.loading(AUTH_ABOUT_ME) === "loading" ||
        this.$store.state.LiteCalc.loading ||
        this.loading(GET_ORDER) === "loading"
      );
    },
    orderNumber() {
      return this.$route.params.id;
    },
    isDisabled() {
      return this.disabled;
    },
    calculatorMode() {
      return this.$route.name === "updateReservation" ? "update" : "new";
    },
    isPriceExist() {
      return  Boolean(this.form.calc_result.price)   || Boolean(this.defaultCalcResult.price)

    },
    traderCalcDisplayRules() {
      const exclusiveOrderRules = this.form.calc.exclusive.enabled === true;
      const rolesUsersRules = ["root", "trader"].includes(this.currentUserRole);
      return exclusiveOrderRules && rolesUsersRules;
    },
    currentUserRole() {
      const {
        type: { id }
      } = this.getUser;
      return id.toLowerCase();
    }
  },
  methods: {
    ...mapActions("Helpers", {
      fetchGeoTaxonomy: GET_GEOTAXONOMY
    }),
    ...mapActions("Orders", {
      getDemography: GET_DEMOGRAPHY,
      getOrder: GET_ORDER,
      getXLS: DOWNLOAD_ORDER_XLS,
      checkXLSExist: CHECK_EXIST_XLS,
      generateXLS: GENERATE_XLS,
      getFormulas: GET_FORMULAS,
      setStatus: SET_ORDER_STATUS,
    }),
    ...mapActions("Users", {
      getUsers: GET_USERS
    }),
    async updateStatus(status) {
      if (this.isNew) return;
      let data = {
        data: {
          status: status,
        },
        id: this.$route.params.id,
      };
      await this.setStatus(data);
    },
    async getGeo() {
      this.$store.commit("LiteCalc/setLoading", true);
      // TODO: Переписать на $store.dispatch("api") когда метод перенесут на прод (как getGeoOld)
      await this.fetchGeoTaxonomy();
      this.$store.commit("LiteCalc/setLoading", false);
    },

    dateFormate(date) {
      date = new Date(date);
      var dd = date.getDate();
      if (dd < 10) dd = "0" + dd;

      var mm = date.getMonth() + 1;
      if (mm < 10) mm = "0" + mm;

      var yy = date.getFullYear() % 100;
      if (yy < 10) yy = "0" + yy;

      return dd + "." + mm + "." + yy;
    },
    async getKeepers() {
      const _brandId = this.isNew
        ? this.$route.params.id
        : this.order?.brand?.id;
      try {
        let res = await window.fetch(
          this.$baseURL + "users/" + _brandId + "/usersToKeepers",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization:
                "Bearer " + window.localStorage.getItem("userToken")
            }
          }
        );

        let keepers = await res.json();
        keepers.data.forEach(a => {
          let data = {
            id: a.id,
            name: a.name
          };
          switch (a.type.id) {
            case "Manager":
              this.keepers.manager.push(data);
              break;
            case "Account":
              this.keepers.account.push(data);
              break;
            case "Trader":
              this.keepers.trader.push(data);
              break;
            default:
              break;
          }
        });
        if (!this.isNew) {
          let dataKeepers = {
            manager: "",
            account: "",
            trader: ""
          };
          console.log("form:", this.form);
          this.form.keepers.forEach(keeper => {
            if (this.keepers.manager.find(el => el.id === keeper.id))
              dataKeepers.manager = keeper.id;
            if (this.keepers.account.find(el => el.id === keeper.id))
              dataKeepers.account = keeper.id;
            if (this.keepers.trader.find(el => el.id === keeper.id))
              dataKeepers.trader = keeper.id;
          });
          this.form.keepers = dataKeepers;
        }
      } catch (e) {
        console.log(e);
      }
    },
    // getOrder
    async getCreatedOrder() {
      //get exist order (use when  order update or for init page);
      await this.getOrder({ id: this.$route.params.id });

      if (!this.checkRequestOnError(GET_ORDER)) {
        setTimeout(() => {
          this.$router.push({ name: "reservation" });
        }, 1000);
      }

      this.form = JSON.parse(JSON.stringify(this.order));
      dbg("this.form", this.form);
      if (!this.order.flights) {
        this.form.flights = [];
      }
      if (!this.hidden_from_manager) {
        this.form.hidden_from_manager = false;
      }
      if (!this.order.priority) {
        this.form.priority = "Normal";
      }
      if (Array.isArray(this.order.calc_raw)) {
        this.form.calc = Object.assign({}, defaultCalc);
      } else {
        this.form.calc = this.order.calc_raw;
        // TODO: удалить это поле и использовать только this.order.exclusive
        this.form.calc.exclusive = { enabled: this.order.exclusive }
      }
      if (
        Array.isArray(this.order.calc_result) ||
        this.order.calc_result === null
      ) {
        this.form.calc_result = {

        };
      }
      // вставляем нужные данные в calc_result
      //

      this.$store.commit("Orders/setDefaultCalcResult", {
        data: this.form.calc_result,
        calc: this.form.calc
      });


    },
    onEditHandler() {
      this.isEdit = true;
    },
    onEditingStatusChange() {
      this.isEdit = false;
    }
  }
};
</script>

<style lang="scss" scoped>

.back-button {
  display: inline;
  cursor: pointer;
  transition: .2s ease-out;
  text-decoration: none;
  &__text {
    display: block;
    font-size: 12px;
    margin: 0 0 -23px 6px;
    color: #000;
  }
  &:hover {
    opacity: .6;
  }
}

.with-scroll {
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.helper-button {
  bottom: 20px;
  position: fixed;
  right: 80px;
  z-index: 20;
}

.custom-card-info {
  cursor: pointer;
  transition: 0.2s ease-out;
  &__title {
    transition: 0.2s ease-out !important;
    color: #9fa2b4;
  }
  &__value {
    transition: 0.2s ease-out !important;
    color: #252733;
  }
  &:hover {
    border-color: var(--v-accent-base);
  }
  &:hover &__title {
    color: var(--v-accent-base);
  }
  &:hover &__value {
    color: var(--v-accent-base);
  }
}
</style>
