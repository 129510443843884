/* eslint-disable */
import { mapState, mapActions, mapGetters } from "vuex";
import { formatDate } from "@/plugins/helpers";
import dbg from "@/plugins/dbg";

export default {
  data() {
    return {
      response: [],
      formatRequest: {},
      rawData: {}
    };
  },
  computed: {
    ...mapState("LiteCalc", [
      "trafic",
      "frequency",
      "frequencies",
      "bidType",
      "format",
      "cities",
      "regions",
      "countries",
      "min",
      "max",
      "income",
      "sex",
      "responseloading",
      "taxonomyBlocks",
      "countriesIds",
      "regionsIds",
      "citiesIds"
    ]),
    ...mapState("Helpers", ["geoTaxonomy"]),
    ...mapGetters("Orders", {
      order: "getOrder",
      allDemography: "getAllDemography"
    })
  },
  methods: {
    demographySegments(sex) {
      const allowSegments = this.allDemography.filter(
        item => item.gender == sex
      );
      const segments = allowSegments.map(item => item.id);
      const minAges = allowSegments
        .map(item => item.minAge)
        .sort((a, b) => a - b);
      const maxAges = allowSegments
        .map(item => item.maxAge)
        .sort((a, b) => a - b);
      dbg(minAges, maxAges);
      let values = [];
      const { calc } = this.form;

      if (typeof calc.min != "string" && typeof calc.max != "string") {
        const min = minAges.indexOf(calc.min);
        const max = maxAges.indexOf(calc.max);
        for (let i = min; i <= max; i++) {
          segments[i] && values.push(segments[i]);
        }
      } else if (typeof calc.max !== "string") {
        const min = 0;
        const max = maxAges.indexOf(calc.max);
        for (let i = min; i <= max; i++) {
          segments[i] && values.push(segments[i]);
        }
      } else if (typeof calc.min !== "string") {
        const min = minAges.indexOf(calc.min);
        const max = segments.length - 1;
        for (let i = min; i <= max; i++) {
          segments[i] && values.push(segments[i]);
        }
      } else {
        values = segments;
      }
      return values;
    },
    getRawData() {
      let {
        trafic,
        frequency,
        frequencies,
        bidType,
        format,
        cities,
        regions,
        countries,
        min,
        max,
        income,
        sex,
        responseloading,
        taxonomyBlocks,
        countriesIds,
        regionsIds,
        citiesIds
      } = this;
      this.rawData = {
        trafic,
        frequency,
        frequencies,
        bidType,
        format,
        cities,
        regions,
        countries,
        min,
        max,
        income,
        sex,
        responseloading,
        taxonomyBlocks,
        countriesIds,
        regionsIds,
        citiesIds
      };
      return {
        trafic,
        frequency,
        frequencies,
        bidType,
        format,
        cities,
        regions,
        countries,
        min,
        max,
        income,
        sex,
        responseloading,
        taxonomyBlocks,
        countriesIds,
        regionsIds,
        citiesIds
      };
    },
    getRuGeoNamesByIDs(geosIds) {
      const geos = new Array();
      for (const id of geosIds) {
        let item = id.id ? id.id : id;
        geos.push(this.geoTaxonomy[item].name);
      }
      return geos;
    },
    async getForecast() {
      let { calc, flights } = this.form;

      // if (calc.hasOwnProperty("min") === false) calc.min = 17;
      // if (calc.hasOwnProperty("max") === false) calc.max = 59;

      let data = {
        stat: false,
        group: ["campaignType"]
      };
      let filter = [];
      // Заполняем кампэйн тайпы
      if (calc.format == "vast") {
        filter.push({
          field: "campaignType",
          operation: "eq",
          values: ["vast", "vpaid"]
        });
      } else {
        filter.push({
          field: "campaignType",
          operation: "eq",
          values: [calc.format]
        });
      }
      if (calc.trafic != "all") {
        let trafic = [].concat(calc.trafic);
        if (calc.trafic.includes("mobile")) {
          trafic.push("tablet");
        }
        filter.push({
          field: "device",
          operation: "eq",
          values: trafic
        });
      }
      // Заполняем фильтр
      // --- Гео
      if (calc.cities.length != 0) {
        filter.push({
          field: "city",
          operation: "eq",
          values: this.getRuGeoNamesByIDs(calc.cities)
        });
      }
      if (calc.regions.length != 0) {
        filter.push({
          field: "region",
          operation: "eq",
          values: this.getRuGeoNamesByIDs(calc.regions)
        });
      }
      if (calc.countries.length != 0) {
        filter.push({
          field: "country",
          operation: "eq",
          values: this.getRuGeoNamesByIDs(calc.countries)
        });
      }
      // --- segments
      //     \---  Доход
      if (calc.income.length === 0) {
        calc.income.push("");
      }
      if (!calc.income.includes("")) {
        let values = calc.income.join(", ").split(", ");
        // values.push("0:40014581");
        filter.push({
          field: "segments",
          operation: "eq",
          values
        });
      }
      //      \--- Дерево сегментов
      if (calc.segments[0].length > 0) {
        const keys = [];
        const nodes = calc.segments[0];
        for (const nodeIndex in nodes) {
          if (nodes.hasOwnProperty(nodeIndex)) {
            keys.push(nodes[nodeIndex]);
          }
        }
        filter.push({
          field: "segments",
          operation: "eq",
          values: keys
        });
      }
      if (calc.segments[1].length > 0) {
        const keys = [];
        const nodes = calc.segments[1];
        for (const nodeIndex in nodes) {
          if (nodes.hasOwnProperty(nodeIndex)) {
            keys.push(nodes[nodeIndex]);
          }
        }
        filter.push({
          field: "segments",
          operation: "eq",
          values: keys
        });
      }
      //      \--- Демография
      let isSexSetted = calc.sex[0] || calc.sex[1];
      if (isSexSetted || calc.min !== "" || calc.max !== "") {
        if (calc?.sex[0] && !calc?.sex[1]) {
          let values = this.demographySegments("Мужчины");

          filter.push({
            field: "segments",
            operation: "eq",
            values
          });
        } else if (calc?.sex[1] && !calc?.sex[0]) {
          let values = this.demographySegments("Женщины");
          filter.push({
            field: "segments",
            operation: "eq",
            values
          });
        } else {
          let values = this.demographySegments("Женщины");
          values.push(...this.demographySegments("Мужчины"));
          values.push(...this.demographySegments("Not Defined"));
          filter.push({
            field: "segments",
            operation: "eq",
            values
          });
        }
      }
      data.filter = filter;
      data.bidType = calc.bidType;
      data.frequency = calc.frequency
        ? calc.frequency
        : this.frequencies[calc.format];
      this.formatRequest = data;
      this.$store.commit("LiteCalc/setCalcRequest", {
        formatRequest: data,
        rawData: this.form
      });
      this.forecastLoading = true;

      data.flights = [
        flights[0].date_start,
        flights[flights.length - 1].date_end
      ];

      let paramID = this.form.id ? this.form.brand.id : this.$route.params.id;
      console.log("paramID", paramID);

      try {
        this.$store.commit("LiteCalc/setResponseLoading", true);

        let response =
          await fetch(this.$baseURL + "calc/" + paramID, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + window.localStorage.getItem("userToken")
            },
            body: JSON.stringify(data)
          })
        const status = response.status;
        const forecastRecievedDate = formatDate(new Date(), {
          withTime: true,
          separator: "-"
        });
        this.form.calc.forecastDate = forecastRecievedDate;

        let json = await response.json()
        if (!this.form.calc.frequency || this.form.calc.frequency < 1)
          this.form.calc.frequency = json.max_frequency;

        if (this.form.calc.frequency > json.max_frequency) {
          this.form.calc.frequency = json.max_frequency;
          this.$notify({
            type: "err",
            title: `Частота не может быть более ${json.max_frequency} для заданного периода`
          });
        }
        this.form.price_key = json.price_key;
        this.$store.commit("Orders/setDefaultCalcResult", {
          data: json,
          calc: this.form.calc
        });
        this.$store.commit("LiteCalc/setResponseLoading", false);
        if (status != 200) {
          this.$notify({
            type: "err",
            title: "Ошибка получения прогноза",
            text: json.error?.message ?? json.error
          });
        }
        this.forecastLoading = false;

        setTimeout(() => {
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth"
          });
        }, 50);
        this.$emit("editingStatusChange");
      }
      catch (err) {
        this.$store.commit("LiteCalc/setResponseLoading", false);

        this.$notify({
          type: "err",
          title: "Ошибка получения прогноза",
          text: err
        });
        this.forecastLoading = false;
      }
          return Promise.resolve();
    }
  }
};
