<template>
  <div class="custom-select">
    <div class="custom-select__title custom-select__title--main">Пол</div>
    <div class='d-flex'>
        <v-checkbox
          :disabled="!isEdit && !isNew"
          :input-value="value[0]"
          @change="$emit('update:value', [$event ? true : false, value[1]])"
          label="М"
          color="accent"
          hide-details
          style="margin: 0 1em 0 0"
        ></v-checkbox>
        <v-checkbox
          :disabled="!isEdit && !isNew"
          :input-value="value[1]"
          @change="$emit('update:value', [value[0], $event ? true : false])"
          label="Ж"
          color="accent"
          hide-details
          style="margin: 0"
        ></v-checkbox>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
//v-model="value"
//@input="$emit('input', $event)"
import { mapGetters } from "vuex";
export default {
  name: "Sex",
  props: {
    isNew: {
      type: Boolean,
      required: false
    },
    isEdit: {
      type: Boolean,
      required: false
    },
    value: {
      type: [String, Array],
      required: true
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("Orders", {
      orderWasDeleted: "orderWasDeleted"
    }),
    sexType() {
      return [
        { value: "Мужчины", name: "Мужчины" },
        { value: "Женщины", name: "Женщины" },
        { value: "Not Defined", name: "Все" }
      ];
    }
  }
};
</script>
