<template>
  <div class="custom-select">
    <div class="custom-select__title custom-select__title--main">
      Формат
    </div>
    <v-select
      :disabled="(!isEdit && !isNew) || format == 'fullscreen'"
      :value="value"
      @input="$emit('input', $event)"
      :items="bidTypes"
      menu-props="auto"
      single-line="single-line"
      item-text="label"
      item-value="value"
      hide-details
      class="custom-select__field pa-0 ma-0"
    ></v-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BuyingType",
  props: {
    isNew: {
      type: Boolean,
      required: false
    },
    isEdit: {
      type: Boolean,
      required: false
    },
    value: {
      type: String,
      required: true
    },
    format: {
      type: String,
      required: false,
      default: "rtb"
    }
  },
  computed: {
    ...mapGetters("Orders", { orderWasDeleted: "orderWasDeleted" }),

    bidTypes() {
      const bidTypes = [
        {
          label: "CPM",
          value: "show"
        }
      ];

      if (this.format !== "vpaid") {
        bidTypes.push({
          label: "CPC",
          value: "click"
        });
      } else {
        bidTypes.push({
          label: "CPV",
          value: "complete"
        });
      }
      return bidTypes;
    }
  }
};
</script>
