<template>
  <v-row>
    <v-col cols="6">
      <div class="custom-select">
        <div class="custom-select__title custom-select__title--main">
          Страны
        </div>
        <v-autocomplete
          :disabled="!isEdit && !isNew"
          :value="countries"
          @input="selectCountryHandler($event)"
          :clearable="true"
          :items="countryDictionary"
          :deletable-chips="true"
          item-text="name"
          item-value="id"
          multiple=""
          hide-details
          class="custom-select__field pa-0 ma-0"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index <= 1" :input-value="item.selected">
              <span
                style="font-size: 12px; max-width: 110px;"
                class="text-truncate"
                >{{ item.name }}</span
              >
            </v-chip>
            <span v-if="index === 2" class="grey--text text-caption">
              (+{{ countries.length - 2 }} others)
            </span>
          </template>
        </v-autocomplete>
      </div>
    </v-col>
    <v-col cols="6">
      <div class="custom-select">
        <div class="custom-select__title custom-select__title--main">Регионы</div>
        <v-autocomplete
          :disabled="!isEdit && !isNew"
          :value="regions"
          @input="selectRegionHandler($event)"
          :clearable="true"
          :items="regionDictionary"
          :deletable-chips="true"
          item-text="name"
          item-value="id"
          multiple=""
          hide-details
          class="custom-select__field pa-0 ma-0"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index <= 1" :input-value="item.selected">
              <span
                style="font-size: 12px; max-width: 110px;"
                class="text-truncate"
                >{{ item.name }}</span
              >
            </v-chip>
            <span v-if="index === 2" class="grey--text text-caption">
              (+{{ regions.length - 2 }} others)
            </span>
          </template>
        </v-autocomplete>
      </div>
    </v-col>
    <v-col cols="6">
      <div class="custom-select">
        <div class="custom-select__title custom-select__title--main">Города</div>
        <v-autocomplete
          :disabled="
            !isEdit &&
              !isNew &&
              (countries.length === 0 || regions.length === 0)
          "
          :value="cities"
          @input="selectCityHandler($event)"
          :clearable="true"
          :items="cityDictionary"
          :deletable-chips="true"
          item-text="name"
          item-value="id"
          multiple=""
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index <= 1" :input-value="item.selected">
              <span
                style="font-size: 12px; max-width: 110px;"
                class="text-truncate"
              >
                {{ item.name }}
              </span>
            </v-chip>
            <span v-if="index === 2" class="grey--text text-caption">
              (+{{ cities.length - 2 }} others)
            </span>
          </template>
        </v-autocomplete>
      </div>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapState } from "vuex";
import { useGeo } from "@/plugins/helpers";
import dbg from "../../../../../../plugins/dbg";
import { GET_GEOTAXONOMY } from "../../../../../../store/const/helpers";

const { sortGeo, addBeforeGeo, removeBadGeo } = useGeo();
import {
  mainCountries,
  mainRegions,
  mainCities,
  badRegions,
  badCities
} from "@/plugins/geoLists";
export default {
  props: {
    isNew: {
      type: Boolean,
      required: false
    },
    isEdit: {
      type: Boolean,
      required: false
    },
    cities: {
      type: Array,
      required: true,
      default: () => []
    },

    countries: {
      type: Array,
      required: true,
      default: () => []
    },
    regions: {
      type: Array,
      required: true,
      default: () => []
    },
    title: {
      type: String,
      required: false,
      default: "Гео"
    }
  },
  data() {
    return {
      isUpdating: false,
      GEOS: {},
      hiddenIncludes: false
    };
  },
  computed: {
    ...mapState("Helpers", { geo: "geoTaxonomy" }),
    ...mapGetters("LiteCalc", {
      loading: "getLoading"
    }),
    ...mapGetters("Orders", { orderWasDeleted: "orderWasDeleted" }),
    countryDictionary() {
      const countries = [];
      // Все страны с parentID = 0
      for (let key in this.geo) {
        if (this.geo[key].parentID === "0" || this.geo[key].parentID === 0) {
          countries.push({ id: key, ...this.geo[key] });
        }
      }
      return addBeforeGeo(sortGeo(countries), mainCountries);
    },
    regionDictionary() {
      let regions = [];
      const countryIDs = this.countryDictionary.map(country => country.id);
      // Все регионы у которых есть связи со страной
      for (let key in this.geo) {
        if (~countryIDs.indexOf(this.geo[key].parentID)) {
          regions.push({ id: key, ...this.geo[key] });
        }
      }
      if (this.countries.length) {
        // Регионы выбраных стран
        regions = regions.filter(
          region => ~this.countries.indexOf(region.parentID)
        );
      }

      regions = removeBadGeo(regions, badRegions);
      return addBeforeGeo(sortGeo(regions), mainRegions);
    },
    cityDictionary() {
      let cities = [];
      const regionIds = this.regionDictionary.map(region => region.id);
      // Все города у которых есть связь с любыми регионами
      for (let key in this.geo) {
        if (~regionIds.indexOf(this.geo[key].parentID)) {
          cities.push({ id: key, ...this.geo[key] });
        }
      }

      if (this.regions.length) {
        // Все города выбраных регионов
        cities = cities.filter(city => ~this.regions.indexOf(city.parentID));
      } else if (this.countries.length) {
        // Выбрать все регионы выбранных стран, если регион не выбран
        // Выберем все города выбраных стран
        let selectedRegions = [];
        const countryIDs = this.countryDictionary.map(country => country.id);
        for (let key in this.geo) {
          if (~countryIDs.indexOf(this.geo[key].parentID)) {
            selectedRegions.push(key);
          }
        }
        cities = cities.filter(city => ~selectedRegions.indexOf(city.parentID));
      }

      cities = removeBadGeo(cities, badCities);
      cities = cities.filter(({ id }) => id !== "9052863180360295829"); // Севастополь
      return addBeforeGeo(sortGeo(cities), mainCities);
    }
  },
  methods: {

    ...mapActions("Helpers", {
      fetchGeoTaxonomy: GET_GEOTAXONOMY
    }),
    selectCityHandler(newValue) {
      this.$emit("update:cities", newValue);
      this.fixGeo();
      if (newValue.length < 3) {
        this.hiddenIncludes = false;
      }
    },
    selectRegionHandler(newValue) {
      let oldValue = [].concat(this.regions);
      this.$emit("update:regions", newValue);
      if (oldValue.length > newValue.length) {
        const diffValue = oldValue.filter(item => !~newValue.indexOf(item));
        this.$emit(
          "update:cities",
          [].concat(
            this.cities.filter(cityID => {
              return !~diffValue.indexOf(this.geo[cityID].parentID);
            })
          )
        );
      }
      this.fixGeo();
    },

    selectCountryHandler(newValue) {
      let oldValue = [].concat(this.countries);
      dbg("CountryHandler", newValue, oldValue);

      if (oldValue.length > newValue.length) {
        const diffValue = oldValue.filter(item => !~newValue.indexOf(item));
        dbg("regions", this.regions, diffValue);

        this.$emit(
          "update:regions",
          [].concat(this.regions).filter(regionID => {
            return !~diffValue.indexOf(this.geo[regionID].parentID);
          })
        );
      }
      this.$emit("update:countries", newValue);
      if (newValue.length <= 0) this.$emit('update:cities', newValue);
      this.fixGeo();
    },
    fixGeo() {
      const countries = this.countries.filter(id => {
        return !this.regions.filter(selectedRegionID => {
          return this.geo[selectedRegionID].parentID === id;
        }).length;
      });
      this.$store.commit("LiteCalc/setValues", {
        value: countries.map(id => this.geo[id].name),
        param: "countries"
      });

      const regions = this.regions.filter(id => {
        return !this.cities.filter(selectedCity => {
          const selectedCityId =
              this.geo[selectedCity].parentID ? this.geo[selectedCity].parentID :
              this.geo[selectedCity.id].parentID ? this.geo[selectedCity.id].parentID :
              this.geo[selectedCity.id].id;

          return selectedCityId === id;
        }).length;
      });
      this.$store.commit("LiteCalc/setValues", {
        value: regions.map(id => this.geo[id].name),
        param: "regions"
      });
    },
    async getGeo() {
      this.$store.commit("LiteCalc/setLoading", true);
      // TODO: Переписать на $store.dispatch("api") когда метод перенесут на прод (как getGeoOld)
      await this.fetchGeoTaxonomy();
      this.$store.commit("LiteCalc/setLoading", false);
    }
  }
};
</script>
