<template>
  <div id="listcontainer">
    <v-row>
      <v-col
        cols="12"
        md="6"
        sm="6"
        v-for="(taxonomyBlock, taxonomyBlockIndex) in filteredSegmentTrees"
        v-show="
          taxonomyBlockIndex == 0 ||
            withIntersection === true ||
            filteredSegmentTrees[1].length > 0
        "
        :key="taxonomyBlockIndex"
      >
        <div class="container-card" style="margin: 0.5em">
          <v-card
            @click="setActiveBlock(taxonomyBlockIndex)"
            :ripple="false"
            :class="{
              active: activeBlock === taxonomyBlockIndex ? true : false
            }"
          >
            <v-card-title
              ><span>Блок №{{ taxonomyBlockIndex + 1 }}</span>
            </v-card-title>
            <v-treeview dense selected-color="primary" :items="taxonomyBlock">
              <template v-slot:append="item">
                <v-btn
                  @click="removeItem(taxonomyBlockIndex, item)"
                  icon
                  :disabled="!isEdit && !isNew"
                >
                  <v-icon>mdi-delete </v-icon>
                </v-btn>
              </template>
            </v-treeview>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import buildTreeMixin from "@/mixins/buildTree";
import dbg from "@/plugins/dbg";

export default {
  mixins: [buildTreeMixin],
  props: {
    value: {
      type: Array,
      required: true
    },
    activeBlock: {
      type: Number,
      required: true
    },
    form: {
      type: Object,
      required: false
    },
    isNew: {
      type: Boolean,
      required: false
    },
    isEdit: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    filteredSegmentTrees() {
      // trees form selected segments
      let trees = [];
      for (const block of this.value) {
        const filteredTaxonomies = this.$store.getters[
          "LiteCalc/getAllTaxonomy"
        ].filter(taxonomyItem =>
          block.includes(taxonomyItem.id || taxonomyItem.pid)
        );
        trees.push(filteredTaxonomies);
      }
      return trees;
    }
  },
  data() {
    return {
      withIntersection: true
    };
  },
  watch: {
    value: {
      handler(val) {
        this.form.calc.segments = val;
      }
    }
  },
  methods: {
    removeItem(blockIndex, { item }) {
      const removeItemId = item.id;
      let segmentsClone = JSON.parse(JSON.stringify(this.value));
      segmentsClone[blockIndex] = segmentsClone[blockIndex].filter(
        segment => segment != removeItemId
      );
      dbg(segmentsClone);
      this.$emit("input", segmentsClone);
    },
    clearBlock(index) {
      this.withIntersection = false;
      let segmentsClone = JSON.parse(JSON.stringify(this.value));
      (segmentsClone[index] = []), this.setActiveBlock(index - 1);
      this.$emit("input", segmentsClone);
    },
    addIntersection() {
      this.withIntersection = true;
      this.setActiveBlock(1);
    },
    setActiveBlock(blockIndex) {
      this.$emit("editActiveBlockIndex", blockIndex);
    }
  }
};
</script>
<style>
#listcontainer .container-card .active {
  background-color: rgba(167, 167, 167, 0.189) !important;
  transition: all 0.5s;
}
#listcontainer .container-card .active .el-tree {
  background-color: transparent !important;
  transition: all 0.5s;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
