<template>
  <v-dialog
    overlay-color="#040b3e"
    overlay-opacity="0.26"
    :value="value"
    @click:outside="closeDialog()"
    @input="$emit('input', !value)"
    width="702"
  >
    <template v-if="buttonText" v-slot:activator="{ on, attrs }">
      <div class="mt-9 custom-button" v-bind="attrs" v-on="on">
        {{ buttonText }}
        <div class="custom-button__icon">
          <v-icon small color="#9FA2B4">mdi-plus</v-icon>
        </div>
      </div>
    </template>
    <template v-else v-slot:activator="{ on, attrs }">
      <v-btn
        rounded
        v-bind="attrs"
        v-on="on"
        x-large
        color="primary"
        style="text-transform: none"
        class="elevation-0 mr-10"
      >
        <slot></slot>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span v-if="typeOfFile === 'order'">{{
          isNew ? "Добавление креатива" : "Редактирование креатива"
        }}</span>
        <span v-if="typeOfFile === 'xls'">
          Загрузить файл
        </span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Название"
                :rules="rule.required"
                v-model.trim="form.filename"
              >
                <template #prepend>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn
                          class="mb-2"
                          fab
                          small
                          :color="Array.isArray(form.file) ? 'warning' : ''"
                        >
                          <v-file-input
                            v-model.trim="form.file"
                            :rules="rule.required"
                            required
                            hide-input
                            hide-details=""
                            class="pb-4 pl-2"
                            @change="parseFile"
                          >
                          </v-file-input>
                        </v-btn>
                      </span>
                    </template>
                    <span>Выбрать файл</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model.trim="form.description"
                label="Описание "
                prepend-icon="mdi-format-title"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <small>* Помечены обязательные поля</small>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn rounded text @click="closeDialog()">
          Отмена
        </v-btn>
        <v-btn
          rounded
          :loading="isLoading"
          v-if="isNew && typeOfFile === 'order'"
          color="primary"
          class="black--text"
          :disabled="!valid"
          @click="uploadCreativeFile()"
        >
          {{ isNew ? "Добавить и сохранить" : "Редактировать и сохранить" }}
        </v-btn>
        <v-btn
          rounded
          :loading="isLoading"
          v-if="typeOfFile === 'xls'"
          color="primary"
          class="black--text"
          :disabled="!valid"
          @click="uploadXLSAccountFile()"
        >
          Прикрепить файл
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import validationRules from "@/mixins/validationRules";
import requestControl from "@/mixins/requestControl";
import { toBase64 } from "@/plugins/helpers";

import { mapActions } from "vuex";
import {
  CHECK_EXIST_XLS,
  GET_ORDER_FILES,
  UPLOAD_ORDER_FILE,
  UPLOAD_XLS_MANUAL
} from "../../../store/const/orders";

let defaultForm = {
  file: [],
  description: "",
  filename: ""
};

export default {
  name: "CreativesPopup",
  mixins: [validationRules, requestControl],
  props: {
    value: {
      type: Boolean,
      required: true
    },
    buttonText: {
      type: String,
      required: false,
      default: undefined
    },
    entity: {
      type: Object,
      required: false,
      default: () => {}
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false
    },
    typeOfFile: {
      type: String,
      required: false,
      default: "order"
    }
  },
  data() {
    return {
      form: JSON.parse(JSON.stringify(defaultForm)),
      valid: null,
      image: ""
    };
  },
  computed: {
    isLoading() {
      return (
        this.loading(UPLOAD_ORDER_FILE) === "loading" ||
        this.loading(GET_ORDER_FILES) === "loading"
      );
    }
  },
  watch: {
    entity(newValue) {
      // выбирает только те элементы обьекта которые в последствии будут использоваться
      if (newValue) {
        let form = {};
        for (const key in defaultForm) {
          if (Object.hasOwnProperty.call(defaultForm, key)) {
            form[key] = newValue[key];
          }
        }
        this.form = Object.assign({}, form);
      } else {
        this.form = Object.assign({}, defaultForm);
      }
    }
  },
  methods: {
    async parseFile(file) {
      this.form.filename = file.name;
      this.image = await toBase64(file);
    },
    ...mapActions("Orders", {
      uploadOrderFile: UPLOAD_ORDER_FILE,
      uploadXLSFile: UPLOAD_XLS_MANUAL,
      fetchFiles: GET_ORDER_FILES,
      checkXLSExist: CHECK_EXIST_XLS
    }),
    async uploadCreativeFile() {
      const isValid = this.$refs.form.validate();
      if (Array.isArray(this.form.file)) {
        this.$notify({
          type: "warn",
          text: "Необходимо загрузить файл"
        });
        return;
      }
      if (isValid) {
        await this.uploadOrderFile({
          data: this.form,
          id: this.$route.params.id
        });
        if (this.checkRequestOnError(UPLOAD_ORDER_FILE)) {
          this.$notify({
            type: "succ",
            text: "Креатив успешно сохранен"
          });
          this.fetchFiles({ id: this.$route.params.id });
          this.closeDialog();
        }
      }
    },
    async uploadXLSAccountFile() {
      const isValid = this.$refs.form.validate();
      if (Array.isArray(this.form.file)) {
        this.$notify({
          type: "warn",
          text: "Необходимо загрузить файл"
        });
        return;
      }
      if (isValid) {
        await this.uploadXLSFile({
          data: { file: this.form.file },
          id: this.$route.params.id
        });
        if (this.checkRequestOnError(UPLOAD_XLS_MANUAL)) {
          this.$notify({
            type: "succ",
            text: "Файл успешно сохранен"
          });
          this.checkXLSExist({ id: this.$route.params.id });
          this.closeDialog();
        }
      }
    },
    closeDialog() {
      this.$emit("input", false);
      // this.form = Object.assign({}, defaultForm);
      this.$refs.form.resetValidation();
      // this.$refs.form.resetVal
    }
  }
};
</script>
<style lang="scss" scoped>
.custom-button {
  font-size: 14px;
  font-weight: 600;
  color: #c5c7cd;
  cursor: pointer;
  .custom-button__icon {
    margin-left: 30px;
    display: inline-flex;
    padding: 4px;
    background: #f0f1f7;
    border-radius: 8px;
  }
}
</style>
