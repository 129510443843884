<template>
  <v-skeleton-loader :loading="syncLoading" type="button button">
    <div>
      <div class="d-flex align-center forecast-actions-block">
        <template v-if="isNew || isEdit">
          <v-btn
            v-show="showMainButton"
            rounded
            x-large
            color="primary"
            style="text-transform: none"
            class="elevation-0 mr-4"
            @click="onSendData"
          >
            {{ isNew ? "Создать бронь" : "Сохранить изменения" }}
          </v-btn>
          <v-btn
            v-show="isExclusive.enabled && !showMainButton"
            rounded
            x-large
            color="primary"
            style="text-transform: none"
            class="elevation-0 mr-4"
            @click="$emit('onSendData')"
          >
            {{ isNew ? "Создать нестандартную бронь" : "Сохранить изменения" }}
          </v-btn>
          <v-btn
            v-if="form.status"
            v-show="
              showMainButton && form.approved_manager && form.approved_trader
            "
            rounded
            x-large
            color="info"
            style="text-transform: none"
            class="elevation-0 mr-4"
            @click="
              changeStatus(
                form.status.id === 'Approving' ? 'in-progress' : 'approving'
              )
            "
          >
            {{
              form.status.id === "Approving"
                ? 'Перевести в статус "Согласовано"'
                : 'Перевести в статус "В процессе"'
            }}
          </v-btn>
          <v-btn
            v-if="form.status"
            v-show="
              form.status.id === 'InProgress' &&
                showMainButton &&
                form.approved_manager &&
                form.approved_trader
            "
            rounded
            x-large
            color="info"
            style="text-transform: none"
            class="elevation-0 mr-4"
            @click="changeStatus('done')"
          >
            Перевести в статус "Завершено"
          </v-btn>
        </template>
        <v-btn
          @click="submitForecast"
          v-show="isExclusive.enabled === false"
          rounded
          x-large
          :loading="forecastLoading"
          color="primary"
          style="text-transform: none"
          class="elevation-0 mr-10"
        >
          Получить прогноз
        </v-btn>
        <creatives-popup
          v-if="
            isExclusive.enabled &&
              !isNew &&
              ['root', 'account'].includes(currentUserRole)
          "
          type-of-file="xls"
          v-model="uploadDialog"
        >
          Загрузить файл
        </creatives-popup>
        <v-btn
          v-show="isExcelExist"
          :link="true"
          :plain="true"
          :disabled="isXLSLoading || !isForecastActual"
          @click="downloadXLS"
          rounded
          x-large
          color="accent"
        >
          Скачать xls-файл
          <v-icon right>mdi-microsoft-excel</v-icon>
        </v-btn>
        <v-progress-circular
          v-if="isXLSLoading && form.typeForecast === 'flights'"
          indeterminate
          color="#dddb00"
        ></v-progress-circular>
      </div>
      <div class="my-6" v-if="!isExclusive.enabled">
        <v-icon>mdi-lightbulb</v-icon>
        Полученный прогноз является предполагаемым, фактические показатели могут
        отличаться
      </div>
    </div>
  </v-skeleton-loader>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import getState from "@/mixins/getCalculatorState";
import requestControl from "@/mixins/requestControl";
import {
  CREATE_ORDER,
  UPDATE_ORDER,

  DOWNLOAD_ORDER_XLS, EDIT_ORDER_STATUS
} from "@/store/const/orders";
import { GET_USERS } from "@/store/const/users";
import { mapActions, mapGetters, mapState } from "vuex";
import updateOrder from "@/mixins/updateOrder";
import { CHECK_EXIST_XLS } from "@/store/const/orders";

import { download } from "@/plugins/helpers";
import CreativesPopup from "@/components/management/creatives/Popup";


export default {
  components: {
    CreativesPopup,
  },
  mixins: [getState, requestControl, updateOrder],
  props: {
    isCalc: {
      type: Boolean,
      required: false,
      default: false
    },
    form: {
      type: Object,
      required: true
    },
    isNew: {
      type: Boolean,
      required: false
    },
    isEdit: {
      type: Boolean,
      required: false
    },
    isExclusive: {
      type: Object,
      required: false
    },
  },
  data() {
    return {
      triggeredByForecaster: false,
      forecastLoading: false,
      dialogState: false,
      xlsIsLoaded: false,
      isForecastActual: true,
      showMainButton: true,
      uploadDialog: false
    };
  },
  watch:{
    ['form.typeForecast']:{
      handler(){
        if(!this.triggeredByForecaster && !this.syncLoading) {
          this.showMainButton = false
        }
      },
      deep: true,
      immediate: false
    },
    ['form.calc']:{
      handler(value, oldValue){
        if(!this.triggeredByForecaster && !this.syncLoading && value.calculatedBudget != oldValue.calculatedBudget) {
          this.showMainButton = false
        }
      },
      deep: true,
      immediate: false
    },
    ['form.flights']:{
      handler(){
        if(!this.triggeredByForecaster && !this.syncLoading) {
          this.showMainButton = false
        }
      },
      deep: true,
      immediate: false
    }
  },
  created(){
    if(this.isNew){
      this.showMainButton = false
    }
  },
  computed: {
    ...mapState("LiteCalc", ["calcRequest"]),
    ...mapGetters("Orders", {
      order: "getOrder",
      prices: "getPrices",
      isExcelExist: "getExcelExist",
      syncLoading: "getOrderSyncLoading",
      orderWasDeleted: "orderWasDeleted",
    }),
    ...mapGetters("Auth", {
      currentUserRole: "myRole",
    }),

    calculatorMode() {
      return this.$route.name === "updateReservation" ? "update" : "new";
    },
    isXLSLoading(){
      return this.isForecastActual && !this.isExcelExist
    },
    isUserLoading() {
      return this.loading(GET_USERS) === "loading";
    },
    isEditingLoading() {
      return this.loading(UPDATE_ORDER) === "loading";
    },
    isCreatingLoading() {
      return this.loading(CREATE_ORDER) === "loading";
    },
    xlsButtonRules() {
      return ( this.forecastTypeRadioActive === 1 && this.isFlightsForecastRecievedFlag && this.isExcelExist) || this.forecastLoading
    },
    forSaveAtOrderRules() { // правила для выключения кнопки "save" при расчете на бронь
      return (  !this.isForecastRecievedFlag && !this.isExclusive.enabled) || this.forecastLoading
    },
    forSaveAtFlightRules() { // правила для выключения кнопки "save" при расчете на флайты
      return ( !this.isFlightsForecastRecievedFlag && !this.isExclusive.enabled ) || this.forecastLoading
    }
  },
  methods: {
    ...mapActions("Orders", {
      checkXLSExist: CHECK_EXIST_XLS,
      downloadOrderExcel: DOWNLOAD_ORDER_XLS,
      editStatus: EDIT_ORDER_STATUS

    }),
    async changeStatus(status){
      await this.editStatus({ id: this.$route.params.id, type: status });
      if (this.checkRequestOnError(EDIT_ORDER_STATUS)) {
        this.$notify({
          type: "succ",
          text: `Статус успешно изменен`
        });
          this.$router.push({ name: "reservation" });
      }
    },
    onSendData() {
      this.$emit("onSendData");
      // this.$emit('update:isEdit', true);
      this.triggeredByForecaster = true
      setTimeout(()=>{
        this.triggeredByForecaster = false
        this.isForecastActual = true;
      }, 4000)
    },
    async downloadXLS(){
        let res = await this.downloadOrderExcel({ id: this.$route.params.id });
        download(res.data, 'Calc_flights_forecast.xls');
    },
    async submitForecast() {
      if (!this.form.flights.length) {
        this.$notify({
          type: "warn",
          text: "Необходимо задать период"
        });
        return;
      }

      const _emptyDate = this.form.flights.find(flight => flight['date_start'] === '' || flight['date_end'] === '');
      if (_emptyDate !== undefined) {
        this.$notify({
          type: 'warn',
          text: 'Необходимо заполнить все флайты'
        });
        return;
      }
      try {
        await this.getForecast();
        await  this.checkXLSExist({id: this.$route.params.id})
        this.showMainButton = true;
        this.triggeredByForecaster = true;
        this.isForecastActual = false;
        setTimeout(()=>{
          this.triggeredByForecaster = false
        }, 3000)

      }
      catch (e) {
        this.$notify({
          type: 'warn',
          text: e
        });
      }
    },
  }
};
</script>

<style></style>
